import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene2_3_A extends Scene
{
  talker:EffectAnimation;

    constructor() 
    {
        super();

        this.loader.add("assets/farmer_2.json")
        .add("assets/farmer_3.json")
        .add("assets/farmer_4.json")
        .add("assets/farmer_5.json")
        .add("assets/hobot4.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.DoStart();
          }
        );

    }

    DoStart():void
    {
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgfarm.jpg", true);
      view.addChild(bg);

      
      
      // let talker = new Image("farmer.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);


      this.talker = new EffectAnimation(this.GetTexture("assets/farmer_2.json") );
      this.talker.x = 191+437/2;
      this.talker.y = 409+408/2; 
      view.addChild(this.talker);
      
      let target = ModelManager.instance().getData("target");


      if (target=="C2_3_A")
      {
        setTimeout(()=>this.talker.SetAni(this.GetTexture("assets/farmer_4.json")) , 4000 );
      }else if (target=="C2_3_B")
      {
        setTimeout(()=>this.talker.SetAni(this.GetTexture("assets/farmer_3.json")) , 4000 );
      }else if (target=="C2_3_C")
      {
        setTimeout(()=>this.talker.SetAni(this.GetTexture("assets/farmer_5.json")) , 4000 );
      }


      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      
      // 123456789012345678
      //let TextBox1= new TextBox("와! 금방 찾았네!\n겹아가리 톱니무늬 바리는\n 점토를 눌러 접어 입구를\n 두겹으로 만들어. 입구에\n 톱니무늬를 선을 새기듯이\n 그려넣고 톱니 가운데에\n 점으로 포인트를 줬지!\n 꼭 밥그릇처럼 생기지\n 않았어?");
      //let TextBox1= new TextBox(Config.GetTargetDesc(target));      

      let TextBox1= new TextBox(Config.GetTargetDesc0(target));      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      setTimeout(()=>TextBox1.init(Config.GetTargetDesc1(target)) ,Config.GetTargetDesc0(target).length * 100 + 1000 );


      let startButton = new Button("별말씀을! 찾아서 다행이다",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      
      //startButton.DelayActive(TextBox1.text.length*100);

      startButton.DelayActive((Config.GetTargetDesc0(target).length +Config.GetTargetDesc1(target).length) *100 + 1000 );

      
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next1();
      } );   
    }

    next1():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgfarm.jpg", true);
      view.addChild(bg);

      
      
      

      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );


      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);



      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTargetHide(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      let TextBox1= new TextBox(Config.GetTargetDesc2(target));
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("그러게",30 );


      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(3500);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          ModelManager.instance().setData("zone","C2");
          //ModelManager.instance().setTarget(2,4);
          SceneManager.instance().SetScene(SCENE.NEXTITEM); // 
          
      } );   
    }

} 

export {Scene2_3_A}
