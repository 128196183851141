import { useParams, useNavigate} from "react-router-dom"
import { useEffect, useState} from "react"

import {AFrameScene} from '../../lib/aframe-components'
// import {FloatingBackButton, FloatingActionButton} from '../../lib/material-ui-components'
// import {appBase} from '../../lib/routes'

import {ModelManager} from "../../lib/html5story/ModelManager.ts";

import {GifSlider} from "./components/gif-slider"
import {DragDrop} from "./components/drag-drop"

import './style.css'


const base = ''

window.showingHintTooltipPopup = false;

const Scene = (({match, showTakeButton, setShowTakeButton, targetImage, setTargetImage, arSceneStart, setArSceneStart, showNotFoundPopup, setShowNotFoundPopup, showNotFoundPopupHintTooltip, setShowNotFoundPopupHintTooltip, showGifBox, setShowGifBox, showDragDropBox, setShowDragDropBox}) => {
  // const [showTakeButton, setShowTakeButton] = useState(false)
  const navigate = useNavigate();

  
  const [gifSource, setGifSource] = useState('')
  const [gifIndex, setGifIndex] = useState(0)
  // const [expectedTargetImage, setExpectedTargetImage] = useState(null)
  const [activeTargetImageInfo, setActiveTargetImageInfo] = useState(null)
  const GifList = {
    'C2_1_A': ['/assets/gif/C2_1_A-1st.gif', '/assets/gif/C2_1_A-2nd.gif'],
    'C2_4_A': ['/assets/gif/hunting_spear.gif', '/assets/gif/hunting_trap.gif'],
    'C3_3_A': ['/assets/gif/C3_3_A.gif'],
    'C4_3_A': ['/assets/gif/C4_3_A.gif'],
  }

  const dragViewList = ['C4_2_A']
  // const [targetImage, setTargetImage] = useState(null)

  useEffect(() => {
      return () => {
        setShowTakeButton(false)
        setArSceneStart(false)
        setShowNotFoundPopup(false)
        setShowNotFoundPopupHintTooltip(false)
        setTargetImage(null)
        setActiveTargetImageInfo(null)
        setShowGifBox(false)
      };
  }, []);

  const AllTargetImages = [
   {
      "key":"C2_1_A",
      "value":"주거형태",
      "detail":"",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_1_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_1_A.png",
      "group":"A"
   },
   {
      "key":"C2_2_A",
      "value":"겹아가리 톱니무늬 바리",
      "detail":"점토를 눌러 접어 아가리(입구)를 두겹으로 만드는 방법은 청동기시대 이른 시기에 많이 확인되는 토기 제작 방법입니다.  입구에 톱니무늬를 선을 새기듯이 그려넣고, 톱니 가운데에 점으로 포인트를 준 문양이 돋보입니다. 밥그릇과 많이 닮아 있는 것 같죠.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_2_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_2_A.png",
      "group":"A"
   },
   {
      "key":"C2_2_B",
      "value":"시루",
      "detail":"농경은 우리의 먹거리 뿐 아니라, 부엌의 모습도 바꿔놓습니다. 곡식을 먹기 위해 조리 방법과 조리 도구가 변하기 때문입니다. 가장 처음 등장한 방법은 물을 넣고 '삶는 방법'입니다. 냄비 밥 짓는 원리랍니다. 이후 '찌는 방법'이 등장합니다. 구멍이 뚫린 시루를 솥에 걸어 수증기로 떡을 찌는 듯이 밥을 지어 더 찰진 밥을 먹을 수 있게 되었답니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_2_B.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_2_B.png",
      "group":"A"
   },
   {
      "key":"C2_3_A",
      "value":"돌호미",
      "detail":"아래쪽 끝단이 뽀족하고 위가 넓적한 형태입니다. 나무에 묶어 사용했으며, 잡초 뽑기부터 씨앗 심기, 옮겨 심기, 흙파서 뒤집기 등을 해낼 수 있는 만능 농사도구입니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_3_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_3_A.png",
      "group":"A"
   },
   {
      "key":"C2_3_B",
      "value":"돌낫",
      "detail":"농작물을 몸쪽으로 끌어당기듯이 베어서 수확하는데 사용된 도구로, 날카롭게 날을 갈아 사용하였답니다. 보통은 한쪽 면에 날을 만들어 사용하기 때문에 안쪽으로 조금 휘어들어간 형태의 것이 많은데, 이 유물은 입자가 고운 돌이 돌칼(석검)을 재활용하여 양면을 날로 이용했던 것으로 보입니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_3_B.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_3_B.png",
      "group":"A"
   },
   {
      "key":"C2_3_C",
      "value":"반달모양 돌칼",
      "detail":"곡식의 낟알을 거둘때 사용하던 도구로, 대체로 한쪽이 곧고 다른 한쪽은 둥근 반달처럼 생겼다고 해서 반달돌칼이라 불리웁니다. 구멍에 끈을 꿰고 이를 손에 잡고 사용한 것으로 보이며, 물고기를 닮은 어형, 마름모꼴 제형 등 다양한 형태가 확인됩니다. 특히 청동기시대 늦은시기가 되면 날이 둥근 형태에서 각진 형태로 변한 삼각형 석도가 유행하게 됩니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_3_C.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_3_C.png",
      "group":"A"
   },
   {
      "key":"C2_4_A",
      "value":"사냥법",
      "detail":"",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C2_4_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C2_4_A.png",
      "group":"A"
   },
   {
      "key":"C3_1_A",
      "value":"가락바퀴",
      "detail":"청동기시대 사람들이 어떤 옷을 입었을지 생각해볼 수 있는 유물이 바로 이 가락바퀴입니다. 가락바퀴는 실을 잣는 막대(가락)의 중심을 잡아줌과 동시에 회전을 시키는 역할을 합니다. 가락바퀴가 회전하면 실의 원료가 꼬여짐과 동시에 감기면서 실을 뽑아낼 수 있습니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_1_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_1_A.png",
      "group":"B"
   },
   {
      "key":"C3_1_B",
      "value":"돌끌",
      "detail":"나무 도구를 만드는데 사용한 목공구의 하나입니다. 지금은 썩어 남아있지 않지만 청동기시대 사람들은 나무도구를 많이 사용했을 것으로 생각됩니다. 나무 도구를 만들기 위해서는 홈자귀로 찍어 배어낸 나무를 대패날도끼 등을 사용하여 큰 형태를 만들고 비교적 작은 크기의 돌끌을 이용하여 세밀하게 가공하는 과정을 거치게 됩니다. 전면을 갈아 만든 것이 많으며, 특히 날은 세밀하게 갈아서 날카롭게 만든 것이 특징입니다. 자루 없이 바로 사용한 것으로 보입니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_1_B.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_1_B.png",
      "group":"B"
   },
   {
      "key":"C3_2_A",
      "value":"홈줄이 있는 숫돌",
      "detail":"진주 대평리 일대에서는 옥을 가공하여 장신구를 만드는 장인들이 살고 있었답니다. 옥으로 장신구를 만드는 과정은 석기를 만드는 과정과 매우 유사합니다. 원석에서 옥을 필요한 만큼 떼어낸 후, 원하는 형태로 갈아내 모양을 만듭니다. 이후 끈을 꿰기위한 구멍을 뚫게 되는데 이 과정이  끈을 꿰게됩니다. 옥마석에는 옥을 갈았던 방향대로 홈이 남아있어 장인의 흔적을 볼 수 있습니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_2_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_2_A.png",
      "group":"B"
   },
   {
      "key":"C3_3_A",
      "value":"옥",
      "detail":"",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_3_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_3_A.png",
      "group":"B"
   },
   {
      "key":"C3_4_A",
      "value":"붉은간토기",
      "detail":"귀한 손님이 오시는 날. 집에서 가장 좋은 그릇에 음식을 대접해야겠죠. 붉은간토기는 아주 고운 점토를 사용하여 바탕흙을 정성을 다해 갈아내어 매끈하게 만든 후 표면에 산화철을 발라 붉은 광택의 빛을 내는 아주 귀한 토기랍니다. 보통은 소형의 항아리 형태의 것이 발견되는데, 진주 대평리 유적에서는 사발, 컵, 대접 등 다양한 형태의 붉은 간토기가 확인되었답니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_4_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_4_A.png",
      "group":"B"
   },
   {
      "key":"C3_4_B",
      "value":"가지무늬토기",
      "detail":"가지무늬토기는 둥근 몸체에 어깨 부분에 검은색 가지 무늬가 있다는 점이 특징입니다. 토기의 몸체는 고운 입자의 바탕흙을 갈고 닦는 마연기법으로 만들어내어 두께가 매우 얇은 편입니다. 소성 과정에서 어깨부분에 두껍게 먹을 입혀 독특한 문양을 만들어냅니다. 진주지역을 중심으로 하는 경남 서부지역에서 주로 발견되고 있어 남강 유역의 지역성을 보여주는 토기랍니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C3_4_B.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C3_4_B.png",
      "group":"B"
   },
   {
      "key":"C4_1_A",
      "value":"통자루식 간돌검",
      "detail":"평거동 32호 고인돌에서 출토된 간 돌검입니다. 돌감의 결을 살려 정교하게 갈아서 대칭의 아름다움을 표현한 간돌검은 청동기시대 권력자의 권위를 상징하는 유물로 여겨집니다. 청동검 못지 않은 권위가 느껴지시나요?",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C4_1_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C4_1_A.png",
      "group":"C"
   },
   {
      "key":"C4_1_B",
      "value":"간돌화살촉",
      "detail":"청동기시대의 간돌화살촉은 사냥과 전쟁에 사용된 무기로 생각됩니다. 사냥을 위한 화살촉은 폭이 넓고 편평한 것들이 많고, 전투를 위한 화살촉은 폭이 좁고 날렵한 형태입니다. 청동기시대 늦은 시기가 되면, 이전 시기와 비교해 자루에 박기 위한 뽀족한 슴베가 나타나고 폭이 좁고 무거워져  살상용 목적으로 효육성이 높아진 것을 알 수 있습니다. 아마도 집단간의 전쟁이 많아진 것과 관련이 있을 것으로 생각됩니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C4_1_B.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C4_1_B.png",
      "group":"C"
   },
   {
      "key":"C4_2_A",
      "value":"붉은간토기",
      "detail":"귀한 손님이 오시는 날. 집에서 가장 좋은 그릇에 음식을 대접해야겠죠. 붉은간토기는 아주 고운 점토를 사용하여 바탕흙을 정성을 다해 갈아내어 매끈하게 만든 후 표면에 산화철을 발라 붉은 광택의 빛을 내는 아주 귀한 토기랍니다. 보통은 소형의 항아리 형태의 것이 발견되는데, 진주 대평리 유적에서는 사발, 컵, 대접 등 다양한 형태의 붉은 간토기가 확인되었답니다.",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C4_2_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C4_2_A.png",
      "group":"C"
   },
   {
      "key":"C4_3_A",
      "value":"고인돌",
      "detail":"",
      "image_url":"/assets/images/8thwall-scene-page/target-images/C4_3_A.png",
      "shape_image_url":"/assets/images/8thwall-scene-page/target-images-outline/C4_3_A.png",
      "group":"C"
   }
  ]

  const TargetImageGroups = {
    A : ["C2_1_A", "C2_2_A", "C2_2_B", "C2_3_A", "C2_3_B", "C2_3_C", "C2_4_A"], 
    B : ["C3_1_A", "C3_1_B", "C3_2_A", "C3_3_A", "C3_4_A", "C3_4_B"], 
    C : ["C4_1_A", "C4_1_B", "C4_2_A", "C4_3_A"]
  }

  const notHintsImages = ["C2_1_A", "C2_4_A", "C3_3_A", "C4_3_A"];

  const {slug} = useParams()

  // for(let i=0; i < AllTargetImages.length; i++){
  //   let TempTargetImage = AllTargetImages[i];

  //   if(TempTargetImage.key == slug){
  //     setExpectedTargetImage(TempTargetImage);
  //     break;
  //   }
  // }

  let expectedTargetImage = AllTargetImages.find(ti => ti.key == slug);

  let CurrentImageTargets = [];
  // CurrentImageTargets.push(slug);

  if(
    expectedTargetImage && 
    expectedTargetImage.group && 
    typeof TargetImageGroups[expectedTargetImage.group] !== 'undefined' && 
    Array.isArray(TargetImageGroups[expectedTargetImage.group])
  ) {
    CurrentImageTargets = TargetImageGroups[expectedTargetImage.group];
  }


  const toggleHintPopup = (status = false) => {
    setShowNotFoundPopupHintTooltip(status)
    window.showingHintTooltipPopup = status;
  }

  const changeTargetImage = (target = null) => {
    setTargetImage(target)
    // setShowTakeButton(!showTakeButton)
    if(target  == null){
      setShowTakeButton(false)
      setShowGifBox(false)
      setShowDragDropBox(false)
    } else {
      if(target.name != slug) {
        setShowNotFoundPopup(true)
      } else {
        setShowTakeButton(true)
        if (Object.keys(GifList).indexOf(slug) != -1) {
          // show gif view
          if (GifList[slug] && GifList[slug][gifIndex]) {
            setGifSource(GifList[slug][gifIndex])
          }
          
          setShowGifBox(true)
        } else if (dragViewList.indexOf(target.name) != -1) {
          // show drag-drop view
          setShowDragDropBox(true)
          setShowTakeButton(false)
        }
      }
      if(window.showingHintTooltipPopup == false && target.name){
        setActiveTargetImageInfo(AllTargetImages.find(ti => ti.key == target.name))
      }
    }
  }

  const changeGif = (index=null) => {
    
    let gi = (gifIndex + 1) % GifList[slug].length

    if(index != null){
      gi = index;
    }

    setGifIndex(gi);
    setGifSource(GifList[slug][gi])
  }
  // const selectButton = ()=>{
  //   if(showTakeButton){
  //     setShowTakeButton(false)
  //   } else {
  //     setShowTakeButton(true)
  //   }
  // }
  
  const myNamedImageTarget = {
    schema: {
      name: {type: 'string'},
    },
    init() {
      
      const {object3D} = this.el
      const {name} = this.data
      const geometry = {}
      object3D.visible = false

      // console.log('myNamedImageTarget component init', this)

      const checkGeometry = (newGeometry) => {
        // console.log('myNamedImageTarget component check geometry', newGeometry)
        let needsUpdate = false

        const fields = [
          'type',
          'height',
          'radiusTop',
          'radiusBottom',
          'arcLengthRadians',
          'arcStartRadians',
          'scaledWidth',
          'scaledHeight',
        ]
        fields.forEach((f) => {
          if (geometry[f] !== newGeometry[f]) {
            geometry[f] = newGeometry[f]
            needsUpdate = true
          }
        })

        if (needsUpdate) {
          this.el.emit('xrextrasimagegeometry', geometry, false)
        }
      }

      const imageScanning = ({detail}) => {
        // console.log('Image scanning', detail)
        detail.imageTargets.forEach((t) => {
          if (name !== t.name) {
            return
          }
          checkGeometry({type: t.type, ...t.geometry})
        })
      }

      const updateImage = ({detail}) => {
        // console.log('Image Update', detail)
        if (name !== detail.name) {
          return
        }
        object3D.position.copy(detail.position)
        object3D.quaternion.copy(detail.rotation)
        object3D.scale.set(detail.scale, detail.scale, detail.scale)
        object3D.visible = true
      }

      const showImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }
        
        //setShowTakeButton(true)
        
        // setTargetImage(detail)
        changeTargetImage(detail)


        checkGeometry(detail)
        updateImage({detail})
        this.el.emit('xrextrasfound', {}, false)
      }

      const hideImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }

        // setShowTakeButton(false)
        // setTargetImage(null)
        changeTargetImage(null)

        this.el.emit('xrextraslost', {}, false)
        object3D.visible = false
      }

      this.el.sceneEl.addEventListener('xrimagescanning', imageScanning)
      this.el.sceneEl.addEventListener('xrimagefound', showImage)
      this.el.sceneEl.addEventListener('xrimageupdated', updateImage)
      this.el.sceneEl.addEventListener('xrimagelost', hideImage)
    },
  }

  const configTargetsComponent = {
    schema: {
      targets: {type: 'array', default: ['']},
    },
    ensureImageTargetsConfigured() {
      if (this.configured || !this.configOk) {
        return
      }
      const instance = this;
      const imageTargetConfigure = setInterval(function(){
        if(typeof window.XR8 != 'undefined'){

          console.log(`Scanning for targets: ${JSON.stringify(instance.data.targets)}`)
          window.XR8.XrController.configure({imageTargets: instance.data.targets})
          instance.configured = true

          clearInterval(imageTargetConfigure)
        }
      }, 100)
    },
    init() {
      this.configured = false
      this.configOk = false
      this.el.sceneEl.addEventListener('realityready', () => {
        console.log('realityready')
        this.configOk = true
        this.ensureImageTargetsConfigured()
      })
    },
    update() {
      this.configured = false
      this.ensureImageTargetsConfigured()
    },
  }

  const myNamedImageTargetPrimitive = {
    defaultComponents: {
      'my-named-image-target': {},
    },
    mappings: {
      name: 'my-named-image-target.name',
    },
  }

  const RotateComponent = {
      schema: {
        factor: {default: 6},
      },
      init() {
        this.handleEvent = this.handleEvent.bind(this)
        this.el.sceneEl.addEventListener('onefingermove', this.handleEvent)
        this.el.classList.add('cantap')  // Needs "objects: .cantap" attribute on raycaster.
      },
      remove() {
        this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
      },
      handleEvent(event) {
        this.el.object3D.rotation.y += event.detail.positionChange.x * this.data.factor
        this.el.object3D.rotation.x += event.detail.positionChange.y * this.data.factor
      },
  }

  
  const checkTargetImage = () => {
    let arSuccessUrl = 'ARFind';
    let arFailedUrl = 'ARNotFound';


    if( ModelManager.instance().getData("testingScreen") && 
        ( ModelManager.instance().getData("testingScreen") == 'true' || 
          ModelManager.instance().getData("testingScreen") == true )
      ){
      arSuccessUrl = 'ARCheckSuccess';
      arFailedUrl = 'ARCheckFail';      
      ModelManager.instance().setData("testingScreen", false)
    }

    const expectedTargetImageName = slug;
    // const expectedTargetImageName = 'C3_1_A';

    if (targetImage && (expectedTargetImageName === targetImage.name)) {
      //alert('Targeted Object Found!')

      navigate(`${base}/${arSuccessUrl}`, { replace: true });
    } else {

      // ModelManager.instance().setData("errortarget",slug);
      // navigate(`${base}/${arFailedUrl}`, { replace: true });
      
      // alert(`Not Targeted Object! Targeted Object is ${slug}`)
      
      setShowNotFoundPopup(true)
    }
  }




  const TargetImageContainerCode = () => {
    const TargetImageContainers = {
      'C2_1_A' : `<my-named-image-target name="C2_1_A"></my-named-image-target>`,
      'C2_2_A' : `<my-named-image-target name="C2_2_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C2_2_A-obj; mtl: #C2_2_A-mtl" 
                          scale="0.003 0.003 0.003" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C2_2_B' : `<my-named-image-target name="C2_2_B">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C2_2_B-obj; mtl: #C2_2_B-mtl" 
                          scale="0.002 0.002 0.002" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C2_3_A' : `<my-named-image-target name="C2_3_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C2_3_A-obj; mtl: #C2_3_A-mtl" 
                          scale="0.005 0.005 0.005" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C2_3_B' : `<my-named-image-target name="C2_3_B">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C2_3_B-obj; mtl: #C2_3_B-mtl" 
                          scale="0.004 0.004 0.004" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C2_3_C' : `<my-named-image-target name="C2_3_C">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C2_3_C-obj; mtl: #C2_3_C-mtl" 
                          scale="0.004 0.004 0.004" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C2_4_A' : `<my-named-image-target name="C2_4_A"></my-named-image-target>`,

      'C3_1_A' : `<my-named-image-target name="C3_1_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C3_1_A-obj; mtl: #C3_1_A-mtl"
                          scale="0.007 0.007 0.007" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C3_1_B' : `<my-named-image-target name="C3_1_B">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C3_1_B-obj; mtl: #C3_1_B-mtl" 
                          scale="0.007 0.007 0.007" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C3_2_A' : `<my-named-image-target name="C3_2_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C3_2_A-obj; mtl: #C3_2_A-mtl" 
                          scale="0.007 0.007 0.007" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C3_3_A' : `<my-named-image-target name="C3_3_A"></my-named-image-target>`,

      'C3_4_A' : `<my-named-image-target name="C3_4_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C3_4_A-obj; mtl: #C3_4_A-mtl" 
                          scale="0.003 0.003 0.003" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C3_4_B' : `<my-named-image-target name="C3_4_B">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C3_4_B-obj; mtl: #C3_4_B-mtl" 
                          scale="0.002 0.002 0.002" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C4_1_A' : `<my-named-image-target name="C4_1_A">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C4_1_A-obj; mtl: #C4_1_A-mtl" 
                          scale="0.003 0.003 0.003" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C4_1_B' : `<my-named-image-target name="C4_1_B">
                      <a-plane color="#fff" height="100" width="100" rotation="0 0 0" position="0 0 -2"></a-plane>
                      <a-entity 
                          rotate-360 
                          obj-model="obj: #C4_1_B-obj; mtl: #C4_1_B-mtl" 
                          scale="0.009 0.009 0.009" 
                          position='0 0 0'
                      >
                      </a-entity>
                  </my-named-image-target>`,

      'C4_2_A' : `<my-named-image-target name="C4_2_A"></my-named-image-target>`,

      'C4_3_A' : `<my-named-image-target name="C4_3_A"></my-named-image-target>`
    }
    let html = '';
    for(let i = 0; i < CurrentImageTargets.length; i++){
      let cit = CurrentImageTargets[i];
      if(TargetImageContainers[cit]){
        if(slug == cit){
          html += TargetImageContainers[cit]
        } else {
          html += `<my-named-image-target name="${cit}"></my-named-image-target>`
        }
      }
    }
    return html;
  }

  const TargetImageAssetCode = () => {
    const TargetImageAssets = {

      'C2_1_A' : ``,
      'C2_2_A' : `<a-asset-item id="C2_2_A-obj" src="/assets/3d-model/C2_2_A/C2_2_A.obj"></a-asset-item>
                  <a-asset-item id="C2_2_A-mtl" src="/assets/3d-model/C2_2_A/C2_2_A.mtl"></a-asset-item>`,

      'C2_2_B' : `<a-asset-item id="C2_2_B-obj" src="/assets/3d-model/C2_2_B/C2_2_B.obj"></a-asset-item>
                  <a-asset-item id="C2_2_B-mtl" src="/assets/3d-model/C2_2_B/C2_2_B.mtl"></a-asset-item>`,

      'C2_3_A' : `<a-asset-item id="C2_3_A-obj" src="/assets/3d-model/C2_3_A/C2_3_A.obj"></a-asset-item>
                  <a-asset-item id="C2_3_A-mtl" src="/assets/3d-model/C2_3_A/C2_3_A.mtl"></a-asset-item>`,

      'C2_3_B' : `<a-asset-item id="C2_3_B-obj" src="/assets/3d-model/C2_3_B/C2_3_B.obj"></a-asset-item>
                  <a-asset-item id="C2_3_B-mtl" src="/assets/3d-model/C2_3_B/C2_3_B.mtl"></a-asset-item>`,

      'C2_3_C' : `<a-asset-item id="C2_3_C-obj" src="/assets/3d-model/C2_3_C/C2_3_C.obj"></a-asset-item>
                  <a-asset-item id="C2_3_C-mtl" src="/assets/3d-model/C2_3_C/C2_3_C.mtl"></a-asset-item>`,

      'C2_4_A' : ``,

      'C3_1_A' : `<a-asset-item id="C3_1_A-obj" src="/assets/3d-model/C3_1_A/C3_1_A.obj"></a-asset-item>
                  <a-asset-item id="C3_1_A-mtl" src="/assets/3d-model/C3_1_A/C3_1_A.mtl"></a-asset-item>`,

      'C3_1_B' : `<a-asset-item id="C3_1_B-obj" src="/assets/3d-model/C3_1_B/C3_1_B.obj"></a-asset-item>
                  <a-asset-item id="C3_1_B-mtl" src="/assets/3d-model/C3_1_B/C3_1_B.mtl"></a-asset-item>`,

      'C3_2_A' : `<a-asset-item id="C3_2_A-obj" src="/assets/3d-model/C3_2_A/C3_2_A.obj"></a-asset-item>
                  <a-asset-item id="C3_2_A-mtl" src="/assets/3d-model/C3_2_A/C3_2_A.mtl"></a-asset-item>`,

      'C3_3_A' : ``,

      'C3_4_A' : `<a-asset-item id="C3_4_A-obj" src="/assets/3d-model/C3_4_A/C3_4_A.obj"></a-asset-item>
                  <a-asset-item id="C3_4_A-mtl" src="/assets/3d-model/C3_4_A/C3_4_A.mtl"></a-asset-item>`,

      'C3_4_B' : `<a-asset-item id="C3_4_B-obj" src="/assets/3d-model/C3_4_B/C3_4_B.obj"></a-asset-item>
                  <a-asset-item id="C3_4_B-mtl" src="/assets/3d-model/C3_4_B/C3_4_B.mtl"></a-asset-item>`,

      'C4_1_A' : `<a-asset-item id="C4_1_A-obj" src="/assets/3d-model/C4_1_A/C4_1_A.obj"></a-asset-item>
                  <a-asset-item id="C4_1_A-mtl" src="/assets/3d-model/C4_1_A/C4_1_A.mtl"></a-asset-item>`,

      'C4_1_B' : `<a-asset-item id="C4_1_B-obj" src="/assets/3d-model/C4_1_B/C4_1_B.obj"></a-asset-item>
                  <a-asset-item id="C4_1_B-mtl" src="/assets/3d-model/C4_1_B/C4_1_B.mtl"></a-asset-item>`,

      'C4_2_A' : ``,

      'C4_3_A' : ``      
    }

    let assets = ``;

    for(let i = 0; i < CurrentImageTargets.length; i++){
      let cit = CurrentImageTargets[i];
      if(cit == slug && TargetImageAssets[cit]){
        assets += TargetImageAssets[cit]
      }
    }
    return assets;
  }


  return (
    <>
      {
        (()=> {
            if(arSceneStart){
              if(showTakeButton) {
                return false
              } else {
                return (
                  <>
      
                    <div className='camera-msg'>
                        <span>
                          <span style={{color: 'red'}}>{expectedTargetImage?expectedTargetImage.value:''}</span> 모양을 찾아 <br/>
                          카메라로 비춰주세요
                        </span>
                    </div>
    
                    <div className='camera-frame'>
                      <span className='top left'></span>
                      <span className='top right'></span>
                      <span className='bottom left'></span>
                      <span className='bottom right'></span>
                    </div>  
                    <div className="fitem-wrap">
                        <div className="fpic">
                            <img src={(expectedTargetImage && expectedTargetImage.shape_image_url)?expectedTargetImage.shape_image_url+'?v=1':`/assets/images/8thwall-scene-page/etc/etc-ar01.png`} />
                        </div>
                        <div className="tooltip"><span><span style={{color: 'red'}}>{expectedTargetImage?expectedTargetImage.value:''}</span> 이렇게 생겼어!</span></div>
                    </div>
                  </>
                )
              }
            } else {
                return (
                    <>
                        <section className="page-item-wrap">
                            <div className="box-item-wrap">
                                <div className="bmsg">
                                    <span>
                                      <span style={{color: 'red'}}>{expectedTargetImage?expectedTargetImage.value:''}</span> 모양을 찾아  <br/>
                                    카메라로 비춰주세요 
                                    </span>
                                </div>
                                <div className="bpic">
                                    <img src={(expectedTargetImage && expectedTargetImage.shape_image_url)?expectedTargetImage.shape_image_url+'?v=1':`/assets/images/8thwall-scene-page/etc/etc-ar01.png`} />
                                </div>
                            </div>
                
                            <div className="btn-item-wrap">
                                <button type="button" onClick={() => setArSceneStart(true)}>확인</button>
                            </div>
                        </section>
                    </>
                )
            }
        })()
      }

      {
        (() => {
          if (showTakeButton && arSceneStart && !showNotFoundPopup && !showGifBox) {
            return (
              <>
                <img src={'/assets/images/8thwall-scene-page/ico/scene-top-img-title.png'} className='scene-top-img-title' />
              </>
            )
          } else {
            return false
          }
        })()
      }

      {
        (() => {
          if (showTakeButton && arSceneStart && !showNotFoundPopup && !showGifBox) {
            return <button onClick={checkTargetImage} className='take-it-button' >가져가기</button>
          } else {
            return false
          }
        })()
      }

      <GifSlider
        showTakeButton={showTakeButton} 
        arSceneStart={arSceneStart} 
        showNotFoundPopup={showNotFoundPopup} 
        showGifBox={showGifBox} 
        setShowGifBox={setShowGifBox}
        gifSource={gifSource}
        GifList={GifList}
        slug={slug}
        changeGif={changeGif}
        targetImage={targetImage}
        checkTargetImage={checkTargetImage}
        gifIndex={gifIndex}
      ></GifSlider>


      <DragDrop
        arSceneStart={arSceneStart}
        showNotFoundPopup={showNotFoundPopup}
        setShowNotFoundPopup={setShowNotFoundPopup}
        showDragDropBox={showDragDropBox}
        dragViewList={dragViewList}
        slug={slug}
        base={base}
      ></DragDrop>

      {
        (() => {
          if(showNotFoundPopup){

            return (<section className="page-item-wrap not-found-popup">
                  <div className="box-item-wrap">
                      <div className="bclose">
                        <a  onClick={ () => setShowNotFoundPopup(false)}>
                          <img src="/assets/images/8thwall-scene-page/ico/btn-close-fill.png" />
                        </a>
                      </div>
                      <div className="bmsg">
                        찾고있는 유물이<br/>
                        아니에요!
                      </div>
                      {/*<div className="help-item-wrap">
                          <button type="button" onClick={() => toggleHintPopup(true)}>
                            <img src="/assets/images/8thwall-scene-page/ico/ico-hint.png" />
                          </button>
                          
                      </div>*/}
                  </div>
                  {
                    (() => {
                      if(activeTargetImageInfo && notHintsImages.indexOf(activeTargetImageInfo.key) == -1){
                        return (<div className="btn-item-wrap">
                                    <button type="button" onClick={ () => toggleHintPopup(true) }>이 유물은 뭘까요?</button>
                                </div>)
                      } else {
                        return false
                      }
                    })()
                  }
                </section>)
          } else {
            return false
          }
        })()
      }

      {
        (() => {
          if(showNotFoundPopup && showNotFoundPopupHintTooltip){
            return (
              <section className="page-item-wrap not-found-popup-hint">
                <div className="box-item-wrap">
                    <button className="bclose" onClick={() => toggleHintPopup(false)}>
                        <img src="/assets/images/8thwall-scene-page/ico/btn-close.png" />
                    </button>
                    <div className="title">
                      <div className="header">방금 찾은 유물은…</div>
                    </div>
                    <div className="target-image">
                      <img src={(activeTargetImageInfo && activeTargetImageInfo.image_url)?activeTargetImageInfo.image_url:'/assets/images/8thwall-scene-page/ico/ico-close01.png'} />
                    </div>

                    <div>
                      <div className="title">
                        <div className="sub-header">{(activeTargetImageInfo && activeTargetImageInfo.value)?activeTargetImageInfo.value:''}</div>
                        </div>
                      <div className="bmsg">
                        {(activeTargetImageInfo && activeTargetImageInfo.detail)?activeTargetImageInfo.detail:''}
                      </div>
                    </div>
                </div>
            </section>)
          } else {
            return false
          }
        })()
      }
      
      <AFrameScene
        sceneHtml={
          `
            <a-scene
              xrextras-gesture-detector
              xrextras-loading
              xrextras-runtime-error
              landing-page
              config-targets="targets: ${CurrentImageTargets.join()}"
              xrweb="disableWorldTracking: true">
              <a-assets>
                ${TargetImageAssetCode()}
              </a-assets>
              
              <a-camera
                position="0 4 10"
                raycaster="objects: .cantap"
                cursor="fuse: false; rayOrigin: mouse;">
              </a-camera>
              
              <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>
              <a-light type="ambient" intensity="1"></a-light>
              
              ${TargetImageContainerCode()}
            </a-scene>
          `
        }
        components={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTarget,
          },
          {
            'name': 'rotate-360',
            'val': RotateComponent,
          },
          {
            'name': 'config-targets',
            'val': configTargetsComponent,
          },
        ]}
        primitives={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTargetPrimitive,
          },
        ]}
      />
    </>
  )
})
export {Scene}