import { useParams, useNavigate} from "react-router-dom"
import { useEffect} from "react"

import {AFrameScene} from '../../lib/aframe-components'
// import {FloatingBackButton, FloatingActionButton} from '../../lib/material-ui-components'
// import {appBase} from '../../lib/routes'

import {ModelManager} from "../../lib/html5story/ModelManager.ts";

import '../scene/style.css'


const base = ''

const TestAR = (({match, showTakeButton, setShowTakeButton, targetImage, setTargetImage, arSceneStart, setArSceneStart, showNotFoundPopup, setShowNotFoundPopup, showNotFoundPopupHintTooltip, setShowNotFoundPopupHintTooltip}) => {
  // const [showTakeButton, setShowTakeButton] = useState(false)
  const navigate = useNavigate();
  
  // const [targetImage, setTargetImage] = useState(null)

  useEffect(() => {
      return () => {
        setShowTakeButton(false)
        setArSceneStart(false)
        setShowNotFoundPopup(false)
        setShowNotFoundPopupHintTooltip(false)
        setTargetImage(null)
      };
  }, []);


  const {slug} = useParams()

  const changeTargetImage = (target = null) => {
    setTargetImage(target)
    // setShowTakeButton(!showTakeButton)
    if(target  == null){
      setShowTakeButton(false)
    } else {
      setShowTakeButton(true)
    }
  }
  // const selectButton = ()=>{
  //   if(showTakeButton){
  //     setShowTakeButton(false)
  //   } else {
  //     setShowTakeButton(true)
  //   }
  // }
  
  const myNamedImageTarget = {
    schema: {
      name: {type: 'string'},
    },
    init() {
      
      const {object3D} = this.el
      const {name} = this.data
      const geometry = {}
      object3D.visible = false

      // console.log('myNamedImageTarget component init', this)

      const checkGeometry = (newGeometry) => {
        // console.log('myNamedImageTarget component check geometry', newGeometry)
        let needsUpdate = false

        const fields = [
          'type',
          'height',
          'radiusTop',
          'radiusBottom',
          'arcLengthRadians',
          'arcStartRadians',
          'scaledWidth',
          'scaledHeight',
        ]
        fields.forEach((f) => {
          if (geometry[f] !== newGeometry[f]) {
            geometry[f] = newGeometry[f]
            needsUpdate = true
          }
        })

        if (needsUpdate) {
          this.el.emit('xrextrasimagegeometry', geometry, false)
        }
      }

      const imageScanning = ({detail}) => {
        // console.log('Image scanning', detail)
        detail.imageTargets.forEach((t) => {
          if (name !== t.name) {
            return
          }
          checkGeometry({type: t.type, ...t.geometry})
        })
      }

      const updateImage = ({detail}) => {
        // console.log('Image Update', detail)
        if (name !== detail.name) {
          return
        }
        object3D.position.copy(detail.position)
        object3D.quaternion.copy(detail.rotation)
        object3D.scale.set(detail.scale, detail.scale, detail.scale)
        object3D.visible = true
      }

      const showImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }
        
        //setShowTakeButton(true)
        
        // setTargetImage(detail)
        changeTargetImage(detail)


        checkGeometry(detail)
        updateImage({detail})
        this.el.emit('xrextrasfound', {}, false)
      }

      const hideImage = ({detail}) => {
        if (name !== detail.name) {
          return
        }

        // setShowTakeButton(false)
        // setTargetImage(null)
        changeTargetImage(null)

        this.el.emit('xrextraslost', {}, false)
        object3D.visible = false
      }

      this.el.sceneEl.addEventListener('xrimagescanning', imageScanning)
      this.el.sceneEl.addEventListener('xrimagefound', showImage)
      this.el.sceneEl.addEventListener('xrimageupdated', updateImage)
      this.el.sceneEl.addEventListener('xrimagelost', hideImage)
    },
  }

  const configTargetsComponent = {
    schema: {
      targets: {type: 'array', default: ['']},
    },
    ensureImageTargetsConfigured() {
      if (this.configured || !this.configOk) {
        return
      }
      const instance = this;
      const imageTargetConfigure = setInterval(function(){
        if(typeof window.XR8 != 'undefined'){

          console.log(`Scanning for targets: ${JSON.stringify(instance.data.targets)}`)
          window.XR8.XrController.configure({imageTargets: instance.data.targets})
          instance.configured = true

          clearInterval(imageTargetConfigure)
        }
      }, 100)
    },
    init() {
      this.configured = false
      this.configOk = false
      this.el.sceneEl.addEventListener('realityready', () => {
        console.log('realityready')
        this.configOk = true
        this.ensureImageTargetsConfigured()
      })
    },
    update() {
      this.configured = false
      this.ensureImageTargetsConfigured()
    },
  }

  const myNamedImageTargetPrimitive = {
    defaultComponents: {
      'my-named-image-target': {},
    },
    mappings: {
      name: 'my-named-image-target.name',
    },
  }

  const RotateComponent = {
      schema: {
        factor: {default: 6},
      },
      init() {
        this.handleEvent = this.handleEvent.bind(this)
        this.el.sceneEl.addEventListener('onefingermove', this.handleEvent)
        this.el.classList.add('cantap')  // Needs "objects: .cantap" attribute on raycaster.
      },
      remove() {
        this.el.sceneEl.removeEventListener('onefingermove', this.handleEvent)
      },
      handleEvent(event) {
        this.el.object3D.rotation.y += event.detail.positionChange.x * this.data.factor
        this.el.object3D.rotation.x += event.detail.positionChange.y * this.data.factor
      },
  }

  const testTargetImageName = 'test';
  const CurrentImageTargets = [testTargetImageName];

  const checkTargetImage = () => {
    let arSuccessUrl = 'ARCheckSuccess';
    let arFailedUrl = 'ARCheckFail';


    if (targetImage && (testTargetImageName === targetImage.name)) {
      //alert('Targeted Object Found!')

      navigate(`${base}/${arSuccessUrl}`, { replace: true });
    } else {

      ModelManager.instance().setData("errortarget",slug);
      navigate(`${base}/${arFailedUrl}`, { replace: true });


      // alert(`Not Targeted Object! Targeted Object is ${slug}`)
      //setShowNotFoundPopup(true)
    }
  }
  return (
    <>
      {
        (()=> {
            if(arSceneStart){
              if(showTakeButton) {
                return false
              } else {
                return (
                  <>
      
                    <div className='camera-msg'>
                        카메라를 비춰보세요
                    </div>
    
                    <div className='camera-frame'>
                      <span className='top left'></span>
                      <span className='top right'></span>
                      <span className='bottom left'></span>
                      <span className='bottom right'></span>
                    </div>  
                  </>
                )
              }
            } else {
                return (
                    <>
                        <section className="page-item-wrap">
                            <div className="box-item-wrap">
                                <div className="bmsg">
                                    호봇을 비춰주세요
                                </div>
                                <div className="bpic">
                                    <img src="/assets/images/8thwall-scene-page/target-images-outline/test.png" />
                                </div>
                            </div>
                
                            <div className="btn-item-wrap">
                                <button type="button" onClick={() => setArSceneStart(true)}>확인</button>
                            </div>
                        </section>
                    </>
                )
            }
        })()
      }

      {
        (() => {
          if (showTakeButton && arSceneStart && !showNotFoundPopup) {
            return (<section className="page-item-wrap bg-white justify-space-around">
                  <div className="box-item-wrap">
                      <div className="btitle">AR인식 성공!</div>
                      <div className="bmsg">
                          응! 이제 본격적인<br/>
                          청동시 시대로의 여행을 떠나볼까?
                      </div>
                      <div className="bpic animation align-right">
                          <img src="/assets/images/scene/dandi.png" className="bigger" />
                      </div>
                  </div>
      
                  <div className="btn-item-wrap">
                    <button onClick={checkTargetImage} className='take-it-button' >시작</button>
                  </div>
              </section>)
          } else {
            return false
          }
        })()
      }

      {
        (() => {
          if(showNotFoundPopup){
            return (
              <section className="page-item-wrap">
                <div className="box-item-wrap type01">
                    <div className="bclose">
                      <a  >
                        <img src="/assets/images/8thwall-scene-page/ico/ico-close01.png" />
                      </a>
                    </div>
                    <div className="bmsg">
                      찾고있는 유물이<br/>
                      아니에요!
                    </div>
                    <div className="help-item-wrap">
                        <button type="button" onClick={() => setShowNotFoundPopupHintTooltip(!showNotFoundPopupHintTooltip)}>
                          <img src="/assets/images/8thwall-scene-page/ico/ico-hint.png" />
                        </button>
                        {showNotFoundPopup && showNotFoundPopupHintTooltip?<div className="tooltip">이 유물은 아주 고운 점토를 사용하여 표면에 액체 상태의 산화철을 바르고 매끄러운 도구로 문질러서 구운 토기입니다.
                        </div>:''}
                        
                    </div>
                </div>
    
                <div className="btn-item-wrap">
                    <button type="button" onClick={ () => setShowNotFoundPopup(false) }>다시찾기</button>
                </div>
            </section>)
          } else {
            return false
          }
        })()
      }
      
      <AFrameScene
        sceneHtml={
          `
            <a-scene
              xrextras-gesture-detector
              xrextras-loading
              xrextras-runtime-error
              landing-page="mediaSrc: /assets/recorded-version.mp4"
              config-targets="targets: ${CurrentImageTargets.join()}"
              xrweb="disableWorldTracking: true">
              <a-assets>
                <img id="C3_1_A-image" crossorigin="anonymous" src="/assets/images/8thwall-scene-page/target-images-output/C3_1_A.jpg">
              </a-assets>
              
              <a-camera
                position="0 4 10"
                raycaster="objects: .cantap"
                cursor="fuse: false; rayOrigin: mouse;">
              </a-camera>
              
              <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>
              <a-light type="ambient" intensity="1"></a-light>

              <my-named-image-target name="test"></my-named-image-target>
            </a-scene>
          `
        }
        components={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTarget,
          },
          {
            'name': 'rotate-360',
            'val': RotateComponent,
          },
          {
            'name': 'config-targets',
            'val': configTargetsComponent,
          },
        ]}
        primitives={[
          {
            'name': 'my-named-image-target',
            'val': myNamedImageTargetPrimitive,
          },
        ]}
      />
    </>
  )
})
export {TestAR}