import {useEffect} from "react"
import {SceneManager, SCENE} from "../../lib/html5story/SceneManager.ts";
import {ModelManager} from "../../lib/html5story/ModelManager.ts";

// localhost:3000/ARTest?item=C3_1_A
// 테스트
const ARTest = (({location, match}) => {
  
  useEffect(() =>  {
      
    let item = ModelManager.instance().getData("target");

    /// test source
    const urlParameter = window.location.search;
    const urlParams = new URLSearchParams(urlParameter);
    let itemUrl = urlParams.get("item");

    if (itemUrl!=""&&itemUrl!=null&&itemUrl!=undefined)  
    {
        item =itemUrl

        if(item=="Z1_1" ||item=="Z2" ||item=="Z3" ||item=="Z4" )
        {

        }else
        {
          ModelManager.instance().setData("target",item);
          ModelManager.instance().setData("testtarget",item);
          ModelManager.instance().setData("testingScreen",true);
        }        
        
    }

    var dic = {};

    dic['C2_1_A'] =  SCENE.SCENE2_1;
    dic['C2_2_A'] =  SCENE.SCENE2_2;
    dic['C2_2_B'] =  SCENE.SCENE2_2;
    dic['C2_3_A'] =  SCENE.SCENE2_3;
    dic['C2_3_B'] =  SCENE.SCENE2_3;
    dic['C2_3_C'] =  SCENE.SCENE2_3;
    dic['C2_4_A'] =  SCENE.SCENE2_4;
    dic['C3_1_A'] =  SCENE.SCENE3_1;
    dic['C3_1_B'] =  SCENE.SCENE3_1;
    dic['C3_2_A'] =  SCENE.SCENE3_2;
    dic['C3_3_A'] =  SCENE.SCENE3_3;
    dic['C3_4_A'] =  SCENE.SCENE3_4;
    dic['C3_4_B'] =  SCENE.SCENE3_4;
    dic['C4_1_A'] =  SCENE.SCENE4_1;
    dic['C4_1_B'] =  SCENE.SCENE4_1;
    dic['C4_2_A'] =  SCENE.SCENE4_2;
    dic['C4_3_A'] =  SCENE.SCENE4_3;
    

    dic['Z1'] =  SCENE.ZONE1;
    dic['Z1_1'] =  SCENE.ZONE1_1;
    dic['Z2'] =  SCENE.ZONE2;
    dic['Z3'] =  SCENE.ZONE3;

    console.log("item:"+item);

    SceneManager.instance().Init( dic[item] );
    
    
    

  },[])

  return (
  <>
  </>
)
})

export {ARTest}