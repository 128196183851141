import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import { sound } from '@pixi/sound';

class Scene2_4 extends Scene
{
    
    constructor() 
    {
        super();
		
        this.loader.add("assets/hobot1.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );

    }

    start():void
    {
        ModelManager.instance().setData("zone","C2");

        ModelManager.instance().setTarget(2,4);
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bghunt.jpg", true);
      view.addChild(bg);

      
      
      // let talker = new Image("farmer.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);

      // view.addChild(talker);


      
      ///setTimeout(()=>sound.play('stamp'), 2000);

      let startButton = new Button("(???:잡아라!!)",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(0.1);

      view.addChild(startButton);

      // let TextBox1= new TextBox("(???:잡아라!!)", 30,false);
      // TextBox1.x =Config.SCREEN_WIDTH/2;
      // TextBox1.y =  882+43;
      // view.addChild(TextBox1);
     
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next1();
      } );   
    }
    
    next1():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bghunt.jpg", true);
      view.addChild(bg);
        
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot1.json"),1 );


      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 




      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);


      let TextBox1= new TextBox("저기 봐! 대평마을의 사냥꾼들이\n사냥을 하고 있어! 같이 보러 가자!");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("좋아! 얼른 가보자!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.text.length*100);
      view.addChild(startButton);
     
    
      startButton.on('pointerup',()=>
      {
        SceneManager.instance().SetScene(SCENE.TARGET); // 

      } );   
    }


  
} 

export {Scene2_4}
