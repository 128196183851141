import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';

class Scene3_1 extends Scene
{
    constructor() 
    {
        super();

        this.loader.add("assets/owner_1.json")
        .add("assets/owner_3.json")
        .add("assets/hobot1.json")
        .add("assets/hobot4.json")
        .load(
        ()=>{ 
            this.loading=false;
            this.start();
        }
        );


    }

    start():void
    {
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork.jpg", true);
      view.addChild(bg);

      
      
      
      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );

      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 


      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);


      
      // 123456789012345678
      let TextBox1= new TextBox("여긴 대평마을의 공방,\n대평공방이야!\n한번 둘러볼까?");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("좋아! 여기 너무 멋있다~",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      
      startButton.DelayActive(TextBox1.text.length*100);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next1();
      } );   
    }

    next1():void
    {
      
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork.jpg", true);
      view.addChild(bg);


      let startButton = new Button("(??: 아휴 잘 안보이네.)",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      
      startButton.DelayActive(100);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next2();
      } );   
    }

    next2() :void
    {
    
      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork.jpg", true);
      view.addChild(bg);
      
      
      
      let talker = new EffectAnimation(this.GetTexture("assets/owner_1.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);


      let TextBox1= new TextBox("아이구 내 정신 좀 봐.\n손님이 왔구나.");
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("안녕하세요!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+43;
      startButton.DelayActive(TextBox1.text.length*100);
      view.addChild(startButton);
     
    
      let index:number=0;
      startButton.on('pointerup',()=>
      {
        index++;
        if (index==1)
        {

          TextBox1.init("그래 어서 오거라.\n공방을 좀 소개해주고 싶은데\n내가 지금 뭐를 좀 찾는\n 중이라\n좀 어려울 것 같구나…\n둘러보고 있을래?");
          startButton.SetText("찾으시는게 어떻게 생겼는데요?",30);
          startButton.DelayActive(TextBox1.text.length*100);

        }else
        {
          super.removeChild(view);
          this.next3();
        }

      } );   
    }

    next3():void
    {


      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork.jpg", true);
      view.addChild(bg);
      
     

      let talker = new EffectAnimation(this.GetTexture("assets/owner_3.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);

      ModelManager.instance().setTarget(3,1);
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x = Config.SCREEN_WIDTH /2;
      item_circle_bg.y=502 + 156/2-50;
      
      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTarget(target));
      icon_item.x = Config.SCREEN_WIDTH /2;
      icon_item.y=502 + 156/2-50;
      icon_item.FadeLoopCount();

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;

      view.addChild(icon_item);
      

      let TextBox2= new TextBoxBottom("공방주인","이렇게 생겼단다.\n혹시 보게되면 나에게\n좀 가져다 주련?");
      TextBox2.x =Config.SCREEN_WIDTH/2;
      TextBox2.y = 720+50;

      view.addChild(TextBox2);

      let startButton = new Button("네! 제가 찾아드릴께요!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(3500);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          super.removeChild(view);
          this.next4();

      } );   
    }


    ///
    next4():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bgwork.jpg", true);
      view.addChild(bg);
      

      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot1.json"),1 );

      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);
      
      let target = ModelManager.instance().getData("target");

      let item_circle_bg = new Image("item-circle-bg.png");
      item_circle_bg.x =100+156/2-50;
      item_circle_bg.y=790 + 156/2-100;

      view.addChild(item_circle_bg);

      let icon_item = new Image( Config.GetIconTargetHide(target));
      icon_item.x =100+156/2-50;
      icon_item.y=790 + 156/2-100;

      icon_item.scale.x=0.77;
      icon_item.scale.y=0.77;
      
      icon_item.FadeLoopCount();

      view.addChild(icon_item);

      let msg:string="";
      if (target=='C3_1_A')
      {
        msg ="가락바퀴라...\n찾을 수 있겠어?"
      }

      if (target=='C3_1_B')
      {
        msg ="돌끌이라...\n찾을 수 있겠어?"
      }


      let TextBox1= new TextBox(msg);
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      let startButton = new Button("당연하지! 얼른 찾아보자!",30 );

      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.text.length*100);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          SceneManager.instance().SetScene(SCENE.TARGET); // 
      } );   
    }

} 

export {Scene3_1}
