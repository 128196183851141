import * as PIXI from "pixi.js";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import { Card} from "./Card.ts";
import {Config} from "./Config.ts";
import { Ease, ease } from "pixi-ease"
import { SceneManager, SCENE } from "./SceneManager.ts";
import { sound } from '@pixi/sound';
import { ModelManager } from "./ModelManager.ts";

class SceneNextItem extends Scene
{
    public image_target : Image ;


    public card0:Card; 
    public card1:Card;
    public card2:Card;
    public card3:Card;

    constructor() 
    {
        super();

        let itemList = ModelManager.instance().GetItem();
        
        let target = ModelManager.instance().getData("target");
        let last = false;
        let first = false;

        // switch(target)
        // {
        //     case 'C3_1_A':
        //     case 'C3_1_B':
        //     case 'C4_1_A':
        //     case 'C4_1_B':
        //         last =true;
        //         break;
        //     case 'C2_2_A':
        //     case 'C2_2_B':     
        //     //case 'C2_3_C':     
        //     case 'C3_2_A':     
        //     case 'C4_2_A':     
        //         first =true;
        //         break;
        // }

        // dic['C2_1_A'] =  SCENE.SCENE2_2;
        // dic['C2_2_A'] =  SCENE.SCENE2_3;
        // dic['C2_2_B'] =  SCENE.SCENE2_3;
        // dic['C2_3_A'] =  SCENE.SCENE2_4;
        // dic['C2_3_B'] =  SCENE.SCENE2_4;
        // dic['C2_3_C'] =  SCENE.SCENE2_4;
        // dic['C2_4_A'] =  SCENE.CLEAR;
        // dic['C3_1_A'] =  SCENE.SCENE3_2;
        // dic['C3_1_B'] =  SCENE.SCENE3_2;
        // dic['C3_2_A'] =  SCENE.SCENE3_3; // 시작
        // dic['C3_3_A'] =  SCENE.SCENE3_4;
        // dic['C3_4_A'] =  SCENE.CLEAR;
        // dic['C3_4_B'] =  SCENE.CLEAR;
        // dic['C4_1_A'] =  SCENE.SCENE4_2;
        // dic['C4_1_B'] =  SCENE.SCENE4_2;
        // dic['C4_2_A'] =  SCENE.SCENE4_3;
        // dic['C4_3_A'] =  SCENE.CLEAR;

        switch(target)
        {
            case 'C2_4_A':
            case 'C3_4_A':
            case 'C3_4_B':
            case 'C4_3_A':
                last =true;
                break;
            case 'C2_1_A':
            case 'C3_1_A':     
            case 'C3_1_B':     
            case 'C4_1_A':     
            case 'C4_1_B': 
                first =true;
                break;
        }

        let bg:Image = new Image(Config.GetBG(target), true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        let title_get_item:Image = new Image(Config.GetBGTitle(target));
        title_get_item.x = Config.SCREEN_WIDTH/2;
        title_get_item.y = 184; 
        title_get_item.Scale();
        super.addChild(title_get_item);

        let group = new PIXI.Container();
        

///////////////////
        ////////////
        this.card0 = new Card(Config.GetTitleNext(itemList[0]), Config.GetImageNext(itemList[0]), Config.GetImageLockNext(itemList[0]) );
        this.card0.x = 0;
        this.card0.y = Config.SCREEN_HEIGHT/2; 

        if (itemList[0]=="" || first==true)
        {
            this.card0.visible=false;
        }

        this.card0.open();
        
        group.addChild(this.card0);

        this.card1 = new Card(Config.GetTitleNext(itemList[1]),Config.GetImageNext(itemList[1]), Config.GetImageLockNext(itemList[1]));
        if (itemList[1]=="")
        {
            this.card1.visible=false;
        }
        this.card1.x = Config.SCREEN_WIDTH/2;
        this.card1.y =Config.SCREEN_HEIGHT/2; 
        this.card1.openning();
        group.addChild(this.card1);


        this.card2 = new Card(Config.GetTitleNext(itemList[2]),Config.GetImageNext(itemList[2]), Config.GetImageLockNext(itemList[2]));

        if (itemList[2]=="" || last==true)
        {
            this.card2.visible=false;
        }
        
        this.card2.x = Config.SCREEN_WIDTH;
        this.card2.y =Config.SCREEN_HEIGHT/2; 
        this.card2.lock();
        group.addChild(this.card2);

        this.card3 = new Card("","item-dummy.png");
        this.card3.x = Config.SCREEN_WIDTH/2 *3;
        this.card3.y =Config.SCREEN_HEIGHT/2; 
        this.card3.lock();

        if ( last==true)
        {
            this.card3.visible=false;
        }

        group.addChild(this.card3);
        super.addChild(group);
        

        if ( last==true)
        {
            //const eff1 = ease.add(group, { x: -Config.SCREEN_WIDTH/2 } , { duration: 1000,  wait:4000 });
            //eff1.once('complete', () => {this.card2.goTarget();this.card1.goOpen(); } );
    
        }else{
            const eff1 = ease.add(group, { x: -Config.SCREEN_WIDTH/2 } , { duration: 1000,  wait:2500 });
            eff1.once('complete', () => {this.card2.goTarget();this.card1.goOpen(); } );
        }

        //////////////

        let btn_nextbtn_next:Button = new Button("다음",40);
        btn_nextbtn_next.x = Config.SCREEN_WIDTH / 2;
        btn_nextbtn_next.y = 954;
        btn_nextbtn_next.DelayActive2(5500);

        btn_nextbtn_next.on('pointerup',function()
        {
            var dic = {};
         
            // dic['C2_1_A'] =  SCENE.SCENE2_1;
            // dic['C2_2_A'] =  SCENE.SCENE2_2;
            // dic['C2_2_B'] =  SCENE.SCENE2_2;
            // dic['C2_3_A'] =  SCENE.SCENE2_3;
            // dic['C2_3_B'] =  SCENE.SCENE2_3;
            // dic['C2_3_C'] =  SCENE.SCENE2_3;
            // dic['C2_4_A'] =  SCENE.SCENE2_4;
            // dic['C3_1_A'] =  SCENE.CLEAR;
            // dic['C3_1_B'] = SCENE.CLEAR;
            // dic['C3_2_A'] =  SCENE.SCENE3_2; // 시작
            // dic['C3_3_A'] =  SCENE.SCENE3_3;
            // dic['C3_4_A'] =  SCENE.SCENE3_4;
            // dic['C3_4_B'] =  SCENE.SCENE3_4;
            // dic['C4_1_A'] =  SCENE.CLEAR;
            // dic['C4_1_B'] =  SCENE.CLEAR;
            // dic['C4_2_A'] =  SCENE.SCENE4_2;
            // dic['C4_3_A'] =  SCENE.SCENE4_3;

            dic['C2_1_A'] =  SCENE.SCENE2_2;
            dic['C2_2_A'] =  SCENE.SCENE2_3;
            dic['C2_2_B'] =  SCENE.SCENE2_3;
            dic['C2_3_A'] =  SCENE.SCENE2_4;
            dic['C2_3_B'] =  SCENE.SCENE2_4;
            dic['C2_3_C'] =  SCENE.SCENE2_4;
            dic['C2_4_A'] =  SCENE.CLEAR;
            dic['C3_1_A'] =  SCENE.SCENE3_2;
            dic['C3_1_B'] =  SCENE.SCENE3_2;
            dic['C3_2_A'] =  SCENE.SCENE3_3; // 시작
            dic['C3_3_A'] =  SCENE.SCENE3_4;
            dic['C3_4_A'] =  SCENE.CLEAR;
            dic['C3_4_B'] =  SCENE.CLEAR;
            dic['C4_1_A'] =  SCENE.SCENE4_2;
            dic['C4_1_B'] =  SCENE.SCENE4_2;
            dic['C4_2_A'] =  SCENE.SCENE4_3;
            dic['C4_3_A'] =  SCENE.CLEAR;


            sound.stopAll();
            sound.play('bgm',{ loop:true, volume:0.3 });

            let item= ModelManager.instance().getData("target");
            SceneManager.instance().SetScene(dic[item]);

        } );   

        super.addChild(btn_nextbtn_next);
        sound.stopAll();
        sound.play('bgm_item');
    }

   

} 

export {SceneNextItem}
