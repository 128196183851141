import {Config} from "./Config.ts";

class ModelManager {

    private static _instance: ModelManager = null;
    public jwtToken:string="";

    public static instance(): ModelManager {
        if (ModelManager._instance === null) {
            ModelManager._instance = new ModelManager();
        }
        return ModelManager._instance;
    }

    dicItem:Object = {};
    constructor() {
        if (ModelManager._instance) {
            throw new Error("Error: Config instead of new.");
        }
        ModelManager._instance = this;
        
    }

    public init(): void {
         console.log("init");

         this.dicItem = {};

         this.setData('lastscene', ""); // 
         this.setData('userID', ""); // 
         this.setData('token', ""); //
         
         
         this.setData('phoneNo', "");
         this.setData('ordering', "0"); // 순서

         this.setData('target', ""); // 찾아야할 유물
         this.setData('errortarget', ""); // 찰못찾은 유물
         this.setData('zone', ""); // 순서
         this.setData('carditems', "::"); // 찾아야할 유물들 카들

         this.setData('items', ""); // 찾은 유물들
         this.setItemList();
    }

    public setData(key:string , value:string):void
    {
        console.log("setkey:"+key+":"+value);
        localStorage.setItem(key, value);
    }

    public setItemList()
    {
        console.log("setItemList:");

        this.dicItem["20"]="";
        this.dicItem["21"] =this.GetItemStr(2,1);
        this.dicItem["22"] =this.GetItemStr(2,2);
        this.dicItem["23"] =this.GetItemStr(2,3);
        this.dicItem["24"] =this.GetItemStr(2,4);
        this.dicItem["25"]="";
        this.dicItem["30"]="";
        this.dicItem["31"] =this.GetItemStr(3,1);
        this.dicItem["32"] =this.GetItemStr(3,2);
        this.dicItem["33"] =this.GetItemStr(3,3);
        this.dicItem["34"] =this.GetItemStr(3,4);
        this.dicItem["35"]="";
        this.dicItem["40"]="";
        this.dicItem["41"] =this.GetItemStr(4,1);
        this.dicItem["42"] =this.GetItemStr(4,2);
        this.dicItem["43"] =this.GetItemStr(4,3);
        this.dicItem["44"]="";
    }

    private GetItemStr(a:number, b:number):string
    {
        let list = [];    
        switch(a*10+b)
        {
            case 21: list=['A'];
            break;
            case 22: list=['A','B'];
            break;
            case 23: list=['A','B','C'];
            break;
            case 24: list=['A'];
            break;
            case 31: list=['A','B'];
            break;
            case 32: list=['A'];
            break;
            case 33: list=['A'];
            break;
            case 34: list=['A','B'];
            break;
            case 41: list=['A','B'];
            break;
            case 42: list=['A'];
            break;
            case 43: list=['A'];
            break;
        }

        let min =0;
        let max =list.length;

        let index = this.getDataInt("ordering") + a + b;
        index =index % max;

        let item = 'C'+a.toString()+"_"+b.toString()+"_" +list[index];

        //console.log("GetItemStr:"+item);

        return item;
    }

    public setTarget(a:number, b:number):void
    {
        console.log("setTarget:"+a+":"+b);

        let t = this.getData("testtarget");
        if(t==null || t=="")
        {
            let list = [];    
            let IsPush=false;
            switch(a*10+b)
            {
                case 21: list=['A'];//IsPush=true;
                break;
                case 22: list=['A','B'];IsPush=true;
                break;
                case 23: list=['A','B','C'];IsPush=true;
                break;
                case 24: list=['A'];//IsPush=true;
                break;
                case 31: list=['A','B'];IsPush=true;
                break;
                case 32: list=['A'];IsPush=true; 
                break;
                case 33: list=['A']; //IsPush=true;
                break;
                case 34: list=['A','B'];IsPush=true;
                break;
                case 41: list=['A','B'];IsPush=true;
                break;
                case 42: list=['A'];
                break;
                case 43: list=['A'];
                break;
            }
    
            let min =0;
            let max =list.length;
    
            let index = this.getDataInt("ordering") + a + b;
            index =index % max;
    
            // min = Math.ceil(min);
            // max = Math.floor(max);
            // let index= Math.floor(Math.random() * (max - min)) + min; 
            let item = 'C'+a.toString()+"_"+b.toString()+"_" +list[index];

            this.pushCardItemIndex(a,b);

            if (  IsPush == true)
            {
                this.pushItem(item);
            }
    
            
            this.setData("target", item );
    
        }else{
         
            {
                //this.pushCardItem(t);
                this.pushCardItemIndex(a,b);
            }
    
            //if (  IsPush == true)
            {
                this.pushItem(t);
            }

        }

        this.setData("testtarget","");
         
    }

    public getData(key:string):string
    {
        let b:string = localStorage.getItem(key);

        console.log("getkey:"+key+":"+b);
        return b;
    }

    pushCardItemIndex(a:number, b:number):void
    {
        this.setItemList();
        let index0 =a*10+b-1;
        let index1 =a*10+b;
        let index2 =a*10+b+1;
        let list = this.dicItem[index0.toString()] +":"+this.dicItem[index1.toString()] +":"+this.dicItem[index2.toString()]; 

        console.log("pushCardItemIndex:"+list);
        this.setData("carditems", list );
    }


    pushCardItem(item:string):void
    {
        let itemList = this.getData("carditems");
        let list = itemList+":"+item;
        let arr = list.split(':');
        if ( arr.length > 3 )
        {   
            list = arr[1] +":"+arr[2] +":"+arr[3];
        }

        this.setData("carditems", list );
    }

    // pushItem(item:string):void
    // {
    //     let itemList = this.getData("items");

    //     let list = "";
    //     if(itemList=="")
    //     {
    //         list = item;
    //     }else{
    //         list = itemList+":"+item;
    //     }

    //     itemList.split(':');

    //     this.setData("items", list );
    // }
    public GetItem()
    {
        let itemList = this.getData("carditems");
        console.log("itemList:"+itemList);

        return itemList.split(':');
    }
    

    pushItem(item:string):void
    {
        let itemList = this.getData("items");

        let list = "";
        if(itemList==""|| itemList==null )
        {
            list = item;
        }else{
            list = itemList+":"+item;
        }

        let dicList = list.split(':');
        let dic = {};
        for(var i=0;i<dicList.length;i++)
        {
            dic[dicList[i]]=true;
        }
        
        let newList ="";
        
        for (let key in dic) {
            newList+= ":"+key;
        }

        newList = newList.substring(1);

        this.setData("items", newList );
    }


    public getDataInt(key:string):number
    {
        let b:string = localStorage.getItem(key);
        console.log(b);

        if ( b==null)
        {
            return 0;
        }

        if (isNaN(parseInt(b)))
        {
            return 0;
        }

        return parseInt(b);
    }

    public postData(url:string , data:Object)
    {
        console.log("postData:"+JSON.stringify(data));
          return fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, cors, *same-origin
          //mode: 'no-cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'omit', // include, *same-origin, omit
          //credentials: 'same-origin', // include, *same-origin, omit
          body: JSON.stringify(data), // body data type must match "Content-Type" header
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
      })
      .then(response =>response.json()) // parses JSON response into native JavaScript objects

     };

     public postData2(url:string , data:Object)
     {
         console.log("postData:"+JSON.stringify(data));
       // Default options are marked with *
           return fetch(url, {
           method: 'POST', // *GET, POST, PUT, DELETE, etc.
           mode: 'cors', // no-cors, cors, *same-origin
           //mode: 'no-cors', // no-cors, cors, *same-origin
           cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           credentials: 'omit', // include, *same-origin, omit
           //credentials: 'same-origin', // include, *same-origin, omit
           body: JSON.stringify(data), // body data type must match "Content-Type" header
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Authorization': this.getData("token")
           },
           redirect: 'follow', // manual, *follow, error
           referrer: 'no-referrer', // no-referrer, *client
          // body: data, // body data type must match "Content-Type" header
           
       })
       .then(response =>response.json()) // parses JSON response into native JavaScript objects
 
      };
 
      public getWeb(url:string)
      {
            return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            //mode: 'no-cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            //credentials: 'same-origin', // include, *same-origin, omit
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.getData("token")
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
           // body: data, // body data type must match "Content-Type" header
            
        })
        .then(response =>response.json()) // parses JSON response into native JavaScript objects
  
       };

       public getLocation()
      {
            return fetch(Config.URL_LOCATION +"?userId="+ModelManager.instance().getData("userID"), {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            //mode: 'no-cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'omit', // include, *same-origin, omit
            //credentials: 'same-origin', // include, *same-origin, omit
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': this.getData("token")
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
           // body: data, // body data type must match "Content-Type" header
            
        })
        .then(response =>response.json()) // parses JSON response into native JavaScript objects
  
       };


       public postAttendance()
       {
            let item = this.getData("target");
            let r = item.substring(item.length-1);
            //let z = item.substring(0, item.length-2);

            let data = {userId:this.getData("userID") , attendanceResult:r, attendanceZone:Config.GET_ATTENDANCEZONE(item) };


             return fetch(Config.URL_ATTENDANCE, {
             method: 'POST', // *GET, POST, PUT, DELETE, etc.
             mode: 'cors', // no-cors, cors, *same-origin
             cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
             credentials: 'omit', // include, *same-origin, omit
             body: JSON.stringify(data), // body data type must match "Content-Type" header
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Authorization': this.getData("token")
             },
             redirect: 'follow', // manual, *follow, error
             referrer: 'no-referrer', // no-referrer, *client
            // body: data, // body data type must match "Content-Type" header
             
         })
         .then(response =>response.json()) // parses JSON response into native JavaScript objects
   
        };





    public SHA256(s:string):string
    {
      
        var chrsz   = 8;
        var hexcase = 0;
      
        function safe_add (x, y) {
            var lsw = (x & 0xFFFF) + (y & 0xFFFF);
            var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
            return (msw << 16) | (lsw & 0xFFFF);
        }
      
        function S (X, n) { return ( X >>> n ) | (X << (32 - n)); }
        function R (X, n) { return ( X >>> n ); }
        function Ch(x, y, z) { return ((x & y) ^ ((~x) & z)); }
        function Maj(x, y, z) { return ((x & y) ^ (x & z) ^ (y & z)); }
        function Sigma0256(x) { return (S(x, 2) ^ S(x, 13) ^ S(x, 22)); }
        function Sigma1256(x) { return (S(x, 6) ^ S(x, 11) ^ S(x, 25)); }
        function Gamma0256(x) { return (S(x, 7) ^ S(x, 18) ^ R(x, 3)); }
        function Gamma1256(x) { return (S(x, 17) ^ S(x, 19) ^ R(x, 10)); }
      
        function core_sha256 (m, l) {
             
            var K = new Array(0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1,
                0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3,
                0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786,
                0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA,
                0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147,
                0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13,
                0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B,
                0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070,
                0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A,
                0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208,
                0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2);
 
            var HASH = new Array(0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19);
 
            var W = new Array(64);
            var a, b, c, d, e, f, g, h, i, j;
            var T1, T2;
      
            m[l >> 5] |= 0x80 << (24 - l % 32);
            m[((l + 64 >> 9) << 4) + 15] = l;
      
            for ( var i = 0; i<m.length; i+=16 ) {
                a = HASH[0];
                b = HASH[1];
                c = HASH[2];
                d = HASH[3];
                e = HASH[4];
                f = HASH[5];
                g = HASH[6];
                h = HASH[7];
      
                for ( var j = 0; j<64; j++) {
                    if (j < 16) W[j] = m[j + i];
                    else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
      
                    T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
                    T2 = safe_add(Sigma0256(a), Maj(a, b, c));
      
                    h = g;
                    g = f;
                    f = e;
                    e = safe_add(d, T1);
                    d = c;
                    c = b;
                    b = a;
                    a = safe_add(T1, T2);
                }
      
                HASH[0] = safe_add(a, HASH[0]);
                HASH[1] = safe_add(b, HASH[1]);
                HASH[2] = safe_add(c, HASH[2]);
                HASH[3] = safe_add(d, HASH[3]);
                HASH[4] = safe_add(e, HASH[4]);
                HASH[5] = safe_add(f, HASH[5]);
                HASH[6] = safe_add(g, HASH[6]);
                HASH[7] = safe_add(h, HASH[7]);
            }
            return HASH;
        }
      
        function str2binb (str) {
            var bin = Array();
            var mask = (1 << chrsz) - 1;
            for(var i = 0; i < str.length * chrsz; i += chrsz) {
                bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i%32);
            }
            return bin;
        }
      
        function Utf8Encode(string) {
            string = string.replace(/\r\n/g,"\n");
            var utftext = "";
      
            for (var n = 0; n < string.length; n++) {
      
                var c = string.charCodeAt(n);
      
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
      
            }
      
            return utftext;
        }
      
        function binb2hex (binarray) {
            var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
            var str = "";
            for(var i = 0; i < binarray.length * 4; i++) {
                str += hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8+4)) & 0xF) +
                hex_tab.charAt((binarray[i>>2] >> ((3 - i%4)*8  )) & 0xF);
            }
            return str;
        }
      
        s = Utf8Encode(s);
        return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
      
    }



} 

export {ModelManager}