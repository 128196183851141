import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import { Ease, ease } from "pixi-ease"
import { PopupErrorZone } from "./PopupErrorZone.ts";

class SceneZone3 extends Scene
{
    public index:number = 0;
    public startButton:Button;

    public view1:PIXI.Container;
    public view2:PIXI.Container;
    nIntervId:number;
    constructor() 
    {
        super();

        this.view1= new PIXI.Container();

        let bg1=new Image("zone4bg.jpg", true);

        bg1.x = Config.SCREEN_WIDTH/2;
        bg1.y = Config.SCREEN_HEIGHT/2; 

        super.addChild(bg1);

        let text1 = new PIXI.Text("옥 목걸이를 접촉해주세요", {
          fontFamily: "font1000",
          fontSize: 40,
          fill: "#ffffff",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
          });
    
          text1.anchor.set(0.5);
          text1.x= Config.SCREEN_WIDTH/2;
          text1.y= 705+50; 
          this.view1.addChild(text1);


          // 옥
        let image_01_copy=new Image("image-01-copy.png");

        image_01_copy.x = Config.SCREEN_WIDTH/2;
        image_01_copy.y = 349 + 305/2; 

        image_01_copy.MoveSin();
        
        this.view1.addChild(image_01_copy);

        super.addChild(this.view1);

        this.view2= new PIXI.Container();

       
        let map_dummy=new Image("mapbg.png");

        map_dummy.x = Config.SCREEN_WIDTH/2;
        map_dummy.y = 483 + 205/2; 
        
        this.view2.addChild(map_dummy);

        let map_dummy4=new Image("map4.png");
        map_dummy4.x = Config.SCREEN_WIDTH/2+120;
        map_dummy4.y = 483 + 205/2 - 60; 
        map_dummy4.ScaleLoop();


        
        this.view2.addChild(map_dummy4);
 

 
        // let arrow=new Image("image-arrow.png");

        // arrow.x = Config.SCREEN_WIDTH/2;
        // arrow.y = 655+ 59/2; 
        // arrow.MoveSin(1, 10);
        
        // this.view2.addChild(arrow);

        let text2 = new PIXI.Text("4관 <영원, 무덤과 의례>로 가서", {
          fontFamily: "font1000",
          fontSize: 40,
          fill: "#ffffff",
          stroke: "black",
          strokeThickness: 0,
          letterSpacing : 0,
          align : 'center'
          });

        text2.anchor.set(0.5);
        text2.x= Config.SCREEN_WIDTH/2;
        text2.y= 300; 
        this.view2.addChild(text2);

        this.view1.visible = false;
        super.addChild(this.view2);

        setInterval( ()=>{ this.toggle() }  , 3000);


        let kiosk_no="";
              
        this.nIntervId = setInterval( ()=>{ 
            ModelManager.instance().getLocation().then((res)=>{
            console.log("getWeb"+JSON.stringify(res));

            if ( res.code == 200 )
            {
              if (res.data.kiosk_no=="4")
              {
                clearInterval(this.nIntervId);
                SceneManager.instance().SetScene(SCENE.SCENE4_1);

              }else
              {

                if (kiosk_no!="" && kiosk_no != res.data.kiosk_no)
                {
                    let popupErrorZone = new PopupErrorZone(this);
                    super.addChild(popupErrorZone);
                }

                kiosk_no = res.data.kiosk_no;
              }
            }
            // 
          });
        }
        , 2000);


        if (Config.SKIP==true)
        {
          let nextButton = new Button("____",30 );
          nextButton.x = 173/2;
          nextButton.y = Config.SCREEN_HEIGHT - 109/2;
          nextButton.alpha = 0;
          super.addChild(nextButton);

          nextButton.on('pointerup', ()=>
          {
            clearInterval(this.nIntervId);
            SceneManager.instance().SetScene(SCENE.SCENE4_1);
          }
          );   
        }


    }

    public toggle():void
    {
      this.view1.visible = !this.view1.visible;
      this.view2.visible = !this.view2.visible;
    }
    
} 

export {SceneZone3}

