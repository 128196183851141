import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {EffectAnimation} from "./EffectAnimation.ts";


class SceneEnd extends Scene
{
    TextBox1 : TextBox ;
    TextBox2 : TextBox ;
    TextBox3 : TextBox ;
    

    view1:PIXI.Container;
    view2:PIXI.Container;
    view3:PIXI.Container;
    view4:PIXI.Container;

    constructor() 
    {
        super();

        		
  this.loader.add("assets/hobot4.json")
  .add("assets/hobot3.json")
  .load(
  ()=>{ 
      this.loading=false;
      this.start();
  }
  );

}

start():void
{
        let bg  = new Image("bg-03.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);

        this.view1=new PIXI.Container();

        super.addChild(this.view1);


        this.view2=new PIXI.Container();

        this.view2.visible = false;

        super.addChild(this.view2);

        this.view3=new PIXI.Container();

        this.view3.visible = false;

        super.addChild(this.view3);


        this.view4=new PIXI.Container();

        this.view4.visible = false;

        super.addChild(this.view4);

       this.TextBox1 = new TextBox("지금까지 너무 잘했어!\n아직 마을이 침략으로 인해\n어수선 하지만,",30);
       this.TextBox1.x =Config.SCREEN_WIDTH/2;
       this.TextBox1.y = 214 + 73/2;

       this.view1.addChild(this.TextBox1);

       let centerImagee = new Image("image-outdoor1.jpg");
       centerImagee.x = 28+565/2;
       centerImagee.y = 486+396/2;
       centerImagee.visible =false;
       this.view1.addChild(centerImagee);

       let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot4.json"),1 );

       dandiimage.x = 254+339/2;
       dandiimage.y = 248+351/2;
       //dandiimage.MoveSin(1, 30); 
       this.view1.addChild(dandiimage);
       ////////////////





       
       let Box2 = new TextBox("남은 미션들을 다 참여하고\n박물관 1층에 가면\n유물과 함께 사진을 찍을 수 있어!",30,false);
       Box2.x =Config.SCREEN_WIDTH/2;
       Box2.y = 214;
       this.view2.addChild(Box2);
 
       let Image2 = new Image("image-outdoor4.png");
       Image2.x =  Config.SCREEN_WIDTH/2;
       Image2.y = Config.SCREEN_HEIGHT/2+50;

       this.view2.addChild(Image2);

       
       let image = new Image("camera.png");
       image.x = Config.SCREEN_WIDTH/2 + 292/2;
       image.y = Config.SCREEN_HEIGHT/2- 445/2+50;
       
       this.view2.addChild(image);















      this.TextBox2 = new TextBox("이제 보고 있는 스마트폰은\n주머니나 가방에 넣어도 돼~!!",30,false);
      this.TextBox2.x =Config.SCREEN_WIDTH/2;
      //this.TextBox2.y = 454 + 73/2;
      this.TextBox2.y = 314 + 73/2;
      this.view3.addChild(this.TextBox2);

      let centerImagee2 = new Image("image-pocket.png");
      centerImagee2.x =  Config.SCREEN_WIDTH/2;
      centerImagee2.y = 480+245/2;
      
      this.view3.addChild(centerImagee2);

      let arraow1 = new Image("end-image-arrow.png");
      arraow1.x = 198+33/2;
      arraow1.y = 441+66/2;
      this.view3.addChild(arraow1);

      let arraow2 = new Image("end-image-arrow.png");
      arraow2.x = 370+33/2;
      arraow2.y = 491+66/2;
      this.view3.addChild(arraow2);

/////////

    this.TextBox3 = new TextBox("그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!",30,false);
    //this.TextBox3 = new TextBox( "남은 미션들을 다 참여하고\n박물관 1층에 가면\n우리가 찾았던 유물과 함께 사진을 찍을 수 있어!\n\n그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!",30,false);
   
    this.TextBox3.x =Config.SCREEN_WIDTH/2;
    //this.TextBox3.y = 454 + 73/2;
    this.TextBox3.y = 214 + 73/2;

    this.view4.addChild(this.TextBox3);


    let dandiimage2 = new EffectAnimation(this.GetTexture("assets/hobot3.json"),1 );

    dandiimage2.x = 254+339/2;
    dandiimage2.y = 248+351/2;
    //dandiimage2.MoveSin(1, 30); 
    this.view4.addChild(dandiimage2);


      setTimeout(()=>{
        //title.visible =false;
        centerImagee.visible =true;
        this.TextBox1.init("AR 디오라마로\n비행 산책도 하고!");
        this.TextBox1.y = 214 + 73/2;
       }, 5000);

      setTimeout(()=>{
        centerImagee.texture = PIXI.Texture.from("./assets/images/scene/image-outdoor2.jpg");
        

        this.TextBox1.init("VR 옥공방에서\n직접 목걸이도 만들어보고");
        this.TextBox1.y = 214 + 73/2;
       }, 9000);

       setTimeout(()=>{

        centerImagee.texture = PIXI.Texture.from("./assets/images/scene/image-outdoor3.jpg");

        this.TextBox1.init("야외 움집도 직접 들어가서\n다른 도움이 필요한지 확인해보자!!");
        this.TextBox1.y = 214 + 73/2;
       }, 13000);

       setTimeout(()=>{

        
        this.view1.visible = false;
        this.view2.visible = true;
        this.view3.visible = false;
        this.view4.visible = false;

       }, 17000);


       setTimeout(()=>{

        this.view1.visible = false;
        this.view2.visible = false;
        this.view3.visible = true;
        this.view4.visible = false;

        setInterval( ()=>{ this.toggle() }  , 4000);
        
       }, 21000);
       
    }
    
    
    public toggle():void
    {
      ModelManager.instance().init();
      
      this.view1.visible = false;
      this.view2.visible = false;
      this.view3.visible = !this.view3.visible;
      this.view4.visible = !this.view4.visible;
      if ( this.view3.visible ==true)
      {
           this.TextBox2.init("이제 보고 있는 스마트폰은\n주머니나 가방에 넣어도 돼~!!");
      }

      if ( this.view4.visible ==true)
      {
            //this.TextBox3.init("남은 미션들을 다 참여하고\n박물관 1층에 가면\n우리가 찾았던 유물과 함께 사진을 찍을 수 있어!\n\n그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!");
            this.TextBox3.init("그럼 다른 미션들도\n잘 참여해봐~~\n\n화이팅~~!!");
            
           
      }
    }

    
} 

export {SceneEnd}
