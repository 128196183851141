import * as PIXI from "pixi.js";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";

class PopupErrorItem extends PIXI.Container 
{
    constructor( parent:Scene, item:string ) {
        super();
        
        let image = new Image("bg-03.jpg", true);
        super.addChild(image);

        let eff = new Image("image-eff-light-bg.png");
        eff.y=300 + 320/2;
        //eff.FadeLoop();
        super.addChild(eff);

        let eff1 = new Image("image-eff-circle-01.png");
        eff1.x=176+286/2;
        eff1.y=389+137/2;
        eff1.FadeLoop();
        super.addChild(eff1);


        let eff2 = new Image("image-eff-circle-02.png");
        eff2.x=137+344/2;
        eff2.y=353+169/2;
        eff2.FadeLoop();
        super.addChild(eff2);

        let itemImage = new Image(Config.GetImageNext(item));
        //itemImage.x=137+344/2;
        itemImage.y=362+178/2;
        //itemImage.FadeLoop();
        super.addChild(itemImage);



        let text = new PIXI.Text("방금 찾은 유물은", {
            fontFamily: "font1000",
            fontSize: 33,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            text.anchor.set(0.5);
            text.x= Config.SCREEN_WIDTH / 2;
            text.y= 188+33/2;
            super.addChild(text);

        let title = new PIXI.Text(Config.GetTitleErrorItem(item), {
            fontFamily: "font1000",
            fontSize: 46,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            title.anchor.set(0.5);
            title.x= Config.SCREEN_WIDTH / 2;
            title.y= 235+43/2;
            super.addChild(title);


        let textArea = new PIXI.Text(Config.GetMsgErrorItem(item), {
            fontFamily: "font1000",
            fontSize: 28,
            fill: "#ffffff",
            stroke: "black",
            strokeThickness: 0,
            letterSpacing : 0,
            align : 'center'
            });
      
            textArea.anchor.set(0.5);
            textArea.x= Config.SCREEN_WIDTH / 2;
            textArea.y= 760;
            super.addChild(textArea);
         

          let closeButton = new Image("btn-close.png");
          closeButton.x = 555+53/2;
          closeButton.y = 31+53/2;

          closeButton.interactive = true;
          closeButton.buttonMode = true;

          closeButton.on('pointerup',()=>
          {
                parent.removeChild(this);
          } );   
        
          super.addChild(closeButton);

        
    }

   
    
} 


export {PopupErrorItem}