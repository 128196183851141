import * as PIXI from "pixi.js";
import { Ease, ease } from "pixi-ease"
import {DataManager} from "./DataManager.ts";

class TextBox2 extends PIXI.Container 
{
    public text:string;

    textArea:PIXI.Text;
    time:number = 0;

    bg : PIXI.NineSlicePlane;

    constructor(text:string, s:number = 30, IsBG:boolean=true) {
        super();
        
        if (IsBG)
        {
            //this.bg =new  PIXI.NineSlicePlane(DataManager.instance().sheet.textures["rect_box.png"], 70, 70, 70, 70);
            this.bg =new  PIXI.NineSlicePlane(PIXI.Texture.from("./assets/images/scene/sctxt-bg.png"), 20, 20, 20, 20);
            //PIXI.Texture.from("./assets/images/scene/button.png"
            this.addChild(this.bg);
        }
        
        //super.anchor.set(0.5);
        this.UpdatePlane(text,s );
    }

    public UpdatePlane(text:string, s:number = 30): void 
    {
        this.time = 0;
        this.text = text;

        let ww = Math.max(158, text.length  * s * 0.8 + 100);

        this.bg.width = ww;
        this.bg.height = 45;

        this.bg.x = -this.bg.width /2;
        this.bg.y = -this.bg.height /2;

        this.width= ww;
        this.height=  45;
        
         this.textArea = new PIXI.Text("", {
         fontFamily: "font1000",
         fontSize: s,
         fill: "#000000",
         stroke: "black",
         strokeThickness: 0,
         letterSpacing : 0,
         align : 'center'
         });
   
         this.textArea.anchor.set(0.5);
         this.textArea.x= 0;
         this.textArea.y= 0;
         super.addChild(this.textArea);
         this.Scale();


         this.gt = 0;
         const eff1 = ease.add( this , { gt: 1  } , { reverse: false, duration: this.text.length * 100 , ease: 'linear' });        
         
     }
     
      public init(text:string, s:number = 30):void
      {
         super.removeChild(this.textArea);
         this.UpdatePlane(text,s);
      }
    

     public Scale(): void 
     {
        this.scale.x = 0.01;
        this.scale.y = 0.01;
         const eff1 = ease.add(this, { scale: 1  } , { reverse: false, duration: 500, ease: 'easeOutBack' });
     }


     _gt:number = 0;
    public get gt():number {
      return this._gt;
    }
 
    public set gt(t:number)
    {
      this._gt = t;
    
      let l:number = Math.min(this.text.length, t* this.text.length  );
       l = Math.max(0, l);

       this.textArea.text = this.text.substring(0,Math.floor(l));
    }

    

    
} 


export {TextBox2}