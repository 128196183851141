import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class Scene4_3_A extends Scene
{

    data:any;
    dragging = false;

    constructor() 
    {
      super();

      this.loader.add("assets/jinju_4.json")
      .add("assets/hobot1.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }


  start():void
  {
    


      ModelManager.instance().setData("zone","C4");

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

     

      let bg = new Image("bg4_2.jpg", true);
      view.addChild(bg);
     

      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);
      // view.addChild(talker);


      
       
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_4.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);





      
      let TextBox1= new TextBox("고생했어! 이제 장례식이 끝났어.\n네 덕분에 수월하게 끝난 것 같아.\n함께 해줘서 고마워!");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);

      setTimeout(()=>{
        super.removeChild(view);
        this.next1();
      }, TextBox1.text.length *100 + 2000);

    }

    next1():void
    {

      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bg4_2.jpg", true);
      view.addChild(bg);
     

      let dandiimage = new EffectAnimation(this.GetTexture("assets/hobot1.json") ,1);


      dandiimage.x = 386+212/2;
      dandiimage.y = 593+234/2-100; 
      //dandiimage.MoveSin(1, 30);

      view.addChild(dandiimage);

      
      let TextBox1= new TextBox("여기서 볼 수 있는건 다\n본 것 같아. 우리 이제\n다음 장소로 넘어가서\n마을을 좀 더 둘러보자");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;
      view.addChild(TextBox1);


      let startButton = new Button("좋아! 가자!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.text.length *100);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
        ModelManager.instance().setData("zone","C4");
        SceneManager.instance().SetScene(SCENE.CLEAR); // 
          
      } );   
    }

  


} 

export {Scene4_3_A}
