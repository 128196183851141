
import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextBoxBottom} from "./TextBoxBottom.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { sound } from '@pixi/sound';
import { Ease, ease } from "pixi-ease"

class Scene4_2_A extends Scene
{

    constructor() 
    {
      super();
       
      this.loader.add("assets/jinju_6.json")
      .load(
      ()=>{ 
          this.loading=false;
          this.start();
      }
      );

  }

  start():void
  {


      let view:PIXI.Container =new PIXI.Container();
      super.addChild(view);

      let bg = new Image("bg4_2.jpg", true);
      view.addChild(bg);
     

      // let talker = new Image("jinju.png");
      // talker.x = 191+437/2;
      // talker.y = 509+408/2; 
      // talker.MoveSin(1, 10);
      // view.addChild(talker);
      


       
        
       
      let talker = new EffectAnimation( this.GetTexture("assets/jinju_6.json") );
      talker.x = 191+437/2;
      talker.y = 509+408/2; 
      view.addChild(talker);


      let TextBox1= new TextBox("잘했어. 우리는 돌아가신\n누군가를 기리기 위해\n토기나 간돌검같은\n부장품을 함께 묻어.\n이런 유물들은 무덤주인의\n권력을 상징하기도 해.");      
      TextBox1.x =Config.SCREEN_WIDTH/2;
      TextBox1.y = 100 + 234/2;

      view.addChild(TextBox1);


      let startButton = new Button("아, 그렇구나! 알겠어!",30 );
      startButton.x = Config.SCREEN_WIDTH / 2;
      startButton.y = 882+93;
      startButton.DelayActive(TextBox1.text.length *100);
      view.addChild(startButton);
    
      startButton.on('pointerup',()=>
      {
          //ModelManager.instance().setTarget(4,3);
          SceneManager.instance().SetScene(SCENE.SCENE4_3); 
          
      } );   
    }
} 

export {Scene4_2_A}
