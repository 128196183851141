import * as PIXI from "pixi.js";
import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {PopupErrorZone} from "./PopupErrorZone.ts";

// ar 찾기 시작 
class SceneTarget extends Scene
{

    constructor() 
    {
        super();
        let target = ModelManager.instance().getData("target");
        SceneManager.instance().goURL("/scene/"+target);

        if ( Config.TEST == true )
        {
            SceneManager.instance().goURL("/ARFind");
    
        }else
        {
            let target = ModelManager.instance().getData("target");
            SceneManager.instance().goURL("/scene/"+target);
    
        }

    }
    
} 

export {SceneTarget}
