import {ModelManager} from "./ModelManager.ts";

class Config {

    //public static TEST:boolean = true;

    public static get TEST():boolean {
        if (  ModelManager.instance().getData("ardebug")=="true" )
        {
            return true;
        }else{

            return false;
        }
    }

    public static get SKIP():boolean {
        if (  ModelManager.instance().getData("arskip")=="true" || ModelManager.instance().getData("ardebug")=="true" )
        {
            return true;
        }else{

            return false;
        }
    }


    private static _SCREEN_WIDTH:number = 640;
    private static _SCREEN_HEIGHT: number = 1136;
 
 
    public static get SCREEN_WIDTH():number {
          return Config._SCREEN_WIDTH;
    }
     
    public static get SCREEN_HEIGHT():number {
          return Config._SCREEN_HEIGHT;   
    }
 
    // 원본 소스 경로
    
    public static URL_LOGIN = "https://www.smartjinju.co.kr/api/v1/login";
    public static URL_LOCATION = "https://www.smartjinju.co.kr/api/v1/location";
    public static URL_ATTENDANCE = "https://www.smartjinju.co.kr/api/v1/attendance/user";
 
 
    public static GET_ATTENDANCEZONE(item:string):string
    {
       var dic = {};
     
       dic['C2_1_A'] = "ARTIFACT_21";
       dic['C2_2_A'] = "ARTIFACT_22";
       dic['C2_2_B'] = "ARTIFACT_22";
       dic['C2_3_A'] = "ARTIFACT_23";
       dic['C2_3_B'] = "ARTIFACT_23";
       dic['C2_3_C'] = "ARTIFACT_23";
       dic['C2_4_A'] = "ARTIFACT_24";
       dic['C3_1_A'] = "ARTIFACT_31";
       dic['C3_1_B'] = "ARTIFACT_31";
       dic['C3_2_A'] = "ARTIFACT_32";
       dic['C3_3_A'] = "ARTIFACT_33";
       dic['C3_4_A'] = "ARTIFACT_34";
       dic['C3_4_B'] = "ARTIFACT_34";
       dic['C4_1_A'] = "ARTIFACT_41";
       dic['C4_1_B'] = "ARTIFACT_41";
       dic['C4_2_A'] = "ARTIFACT_42";
       dic['C4_3_A'] = "ARTIFACT_43";
 
       return  dic[item];
    }
 
    public static GetTitleErrorItem(item:string):string
    {
        var dic = {};
    
        dic['C2_1_A'] = "주거형태";
        dic['C2_2_A'] = "겹아가리 톱니무늬 바리";
        dic['C2_2_B'] = "시루";
        dic['C2_3_A'] = "돌호미";
        dic['C2_3_B'] = "돌낫";
        dic['C2_3_C'] = "반달모양 돌칼";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "가락바퀴";
        dic['C3_1_B'] = "돌끌";
        dic['C3_2_A'] = "홈줄이 있는 숫돌";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "붉은간토기";
        dic['C3_4_B'] = "가지무늬토기";
        //dic['C4_1_A'] = "이단자루식 간돌검";
        dic['C4_1_A'] = "통자루식 간돌검";
        dic['C4_1_B'] = "간돌화살촉";
        dic['C4_2_A'] = "붉은간토기 부장하기";
        dic['C4_3_A'] = "무덤";
 
        return dic[item];
    }
 
    public static GetMsgErrorItem(item:string):string
    {
        var dic = {};
 
        
        //C3_2_A
        dic['C2_1_A'] = "";
        dic['C2_2_A'] = "점토를 눌러 접어 아가리(입구)를 두겹으로 만드는\n 방법은 청동기시대 이른 시기에 많이 확인되는 토기\n 제작 방법입니다.  입구에 톱니무늬를 선을 새기듯이\n 그려넣고, 톱니 가운데에 점으로 포인트를 준 문양이\n 돋보입니다. 밥그릇과 많이 닮아 있는 것 같죠.";
        
        dic['C2_2_B'] = "농경은 우리의 먹거리 뿐 아니라, 부엌의 모습도\n 바꿔놓습니다. 곡식을 먹기 위해 조리 방법과 조리\n 도구가 변하기 때문입니다. 가장 처음 등장한 방법은\n 물을 넣고 '삶는 방법'입니다. 냄비 밥 짓는\n 원리랍니다. 이후 '찌는 방법'이 등장합니다. 구멍이\n 뚫린 시루를 솥에 걸어 수증기로 떡을 찌는 듯이 밥을\n 지어 더 찰진 밥을 먹을 수 있게 되었답니다. ";
        dic['C2_3_A'] = "아래쪽 끝단이 뽀족하고 위가 넓적한 형태입니다.\n 나무에 묶어 사용했으며, 잡초 뽑기부터 씨앗 심기,\n 옮겨 심기, 흙파서 뒤집기 등을 해낼 수 있는 만능\n 농사도구입니다.";
        dic['C2_3_B'] = "농작물을 몸쪽으로 끌어당기듯이 베어서 수확하는데\n 사용된 도구로, 날카롭게 날을 갈아 사용하였답니다.\n 보통은 한쪽 면에 날을 만들어 사용하기 때문에\n 안쪽으로 조금 휘어들어간 형태의 것이 많은데, 이\n 유물은 입자가 고운 돌이 돌칼(석검)을 재활용하여\n 양면을 날로 이용했던 것으로 보입니다. ";
        dic['C2_3_C'] = "곡식의 낟알을 거둘때 사용하던 도구로, 대체로\n 한쪽이 곧고 다른 한쪽은 둥근 반달처럼 생겼다고\n 해서 반달돌칼이라 불리웁니다. 구멍에 끈을 꿰고\n 이를 손에 잡고 사용한 것으로 보이며, 물고기를\n 닮은 어형, 마름모꼴 제형 등 다양한 형태가\n 확인됩니다. 특히 청동기시대 늦은시기가 되면 날이\n 둥근 형태에서 각진 형태로 변한 삼각형 석도가\n 유행하게 됩니다.";
        dic['C2_4_A'] = "";
        dic['C3_1_A'] = "청동기시대 사람들이 어떤 옷을 입었을지 생각해볼\n 수 있는 유물이 바로 이 가락바퀴입니다.\n 가락바퀴는 실을 잣는 막대(가락)의 중심을\n 잡아줌과 동시에 회전을 시키는 역할을 합니다.\n 가락바퀴가 회전하면 실의 원료가 꼬여짐과 동시에\n 감기면서 실을 뽑아낼 수 있습니다. ";
        dic['C3_1_B'] = "나무 도구를 만드는데 사용한 목공구의 하나입니다.\n 지금은 썩어 남아있지 않지만 청동기시대 사람들은\n 나무도구를 많이 사용했을 것으로 생각됩니다. 나무\n 도구를 만들기 위해서는 홈자귀로 찍어 배어낸\n 나무를 대패날도끼 등을 사용하여 큰 형태를 만들고\n 비교적 작은 크기의 돌끌을 이용하여 세밀하게\n 가공하는 과정을 거치게 됩니다. 전면을 갈아 만든\n 것이 많으며, 특히 날은 세밀하게 갈아서 날카롭게\n 만든 것이 특징입니다. 자루 없이 바로 사용한\n 것으로 보입니다.";
        dic['C3_2_A'] = "진주 대평리 일대에서는 옥을 가공하여 장신구를\n 만드는 장인들이 살고 있었답니다. 옥으로 장신구를\n 만드는 과정은 석기를 만드는 과정과 매우\n 유사합니다. 원석에서 옥을 필요한 만큼 떼어낸 후,\n 원하는 형태로 갈아내 모양을 만듭니다. 이후 끈을\n 꿰기위한 구멍을 뚫게 되는데 이 과정이  끈을\n 꿰게됩니다. 옥마석에는 옥을 갈았던 방향대로 홈이\n 남아있어 장인의 흔적을 볼수 있습니다.";
        dic['C3_3_A'] = "";
        dic['C3_4_A'] = "귀한 손님이 오시는 날. 집에서 가장 좋은 그릇에\n 음식을 대접해야겠죠. 붉은간토기는 아주 고운\n 점토를 사용하여 바탕흙을 정성을 다해 갈아내어\n 매끈하게 만든 후 표면에 산화철을 발라 붉은\n 광택의 빛을 내는 아주 귀한 토기랍니다. 보통은\n 소형의 항아리 형태의 것이 발견되는데, 진주\n 대평리 유적에서는 사발, 컵, 대접 등 다양한\n 형태의 붉은 간토기가 확인되었답니다.";
        dic['C3_4_B'] = "가지무늬토기는 둥근 몸체에 어깨 부분에 검은색\n 가지무늬가 있다는 점이 특징입니다. 토기의 몸체는\n 고운 입자의 바탕흙을 갈고 닦는 마연기법으로\n 만들어내어 두께가 매우 얇은 편입니다. 소성\n 과정에서 어깨부분에 두껍게 먹을 입혀 독특한\n 문양을 만들어냅니다. 진주지역을 중심으로 하는\n 경남 서부지역에서 주로 발견되고 있어 남강 유역의\n 지역성을 보여주는 토기랍니다. ";
        dic['C4_1_A'] = "평거동 32호 고인돌에서 출토된 간 돌검입니다.\n 돌감의 결을 살려 정교하게 갈아서 대칭의\n 아름다움을 표현한 간돌검은 청동기시대 권력자의\n 권위를 상징하는 유물로 여겨집니다.\n청동검 못지 않은 권위가 느껴지시나요?";
        dic['C4_1_B'] = "청동기시대의 간돌화살촉은 사냥과 전쟁에 사용된\n 무기로 생각됩니다. 사냥을 위한 화살촉은 폭이\n 넓고 편평한 것들이 많고, 전투를 위한 화살촉은\n 폭이 좁고 날렵한 형태입니다. 청동기시대 늦은\n 시기가 되면, 이전 시기와 비교해 자루에 박기 위한\n 뽀족한 슴베가 나타나고 폭이 좁고 무거워져\n 살상용 목적으로 효육성이 높아진 것을 알 수\n 있습니다. 아마도 집단간의 전쟁이 많아진 것과\n 관련이 있을 것으로 생각됩니다.";
        dic['C4_2_A'] = "";
        dic['C4_3_A'] = "";
 
 
        return dic[item];
    }
    
    ///////
    public static GetTitleNext(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "주거형태";
        dic['C2_2_A'] = "겹아가리 톱니무늬 바리";
        dic['C2_2_B'] = "시루";
        dic['C2_3_A'] = "돌호미";
        dic['C2_3_B'] = "돌낫";
        dic['C2_3_C'] = "반달모양 돌칼";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "가락바퀴";
        dic['C3_1_B'] = "돌끌";
        dic['C3_2_A'] = "홈줄이 있는 숫돌";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "붉은간토기";
        dic['C3_4_B'] = "가지무늬토기";
        dic['C4_1_A'] = "통자루식 간돌검";
        dic['C4_1_B'] = "간돌화살촉";
        dic['C4_2_A'] = "붉은간토기";
        dic['C4_3_A'] = "무덤";
    

        //dic['C4_1_A'] = "이단자루식 간돌검";
        
        return dic[item];
    }
    
    public static GetImageNext(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "CC2_1_A.png";
        dic['C2_2_A'] = "CC2_2_A.png";
        dic['C2_2_B'] = "CC2_2_B.png";
        dic['C2_3_A'] = "CC2_3_A.png";
        dic['C2_3_B'] = "CC2_3_B.png";
        dic['C2_3_C'] = "CC2_3_C.png";
        dic['C2_4_A'] = "CC2_4_A.png";
        dic['C3_1_A'] = "CC3_1_A.png";
        dic['C3_1_B'] = "CC3_1_B.png";
        dic['C3_2_A'] = "CC3_2_A.png";
        dic['C3_3_A'] = "CC3_3_A.png";
        dic['C3_4_A'] = "CC3_4_A.png";
        dic['C3_4_B'] = "CC3_4_B.png";
        dic['C4_1_A'] = "CC4_1_A.png";
        dic['C4_1_B'] = "CC4_1_B.png";
        dic['C4_2_A'] = "CC4_2_A.png";
        dic['C4_3_A'] = "CC4_3_A.png";
    
        return dic[item];
    }
    
    public static GetImageLockNext(item:string):string
    {
        if (item=="")
            return "";
    
        return "L"+item+".png";
        // var dic = {};
    
        // dic['C2_1_A'] = "LC2_1_A.png";
        // dic['C2_2_A'] = "image-locked-dummy.png";
        // dic['C2_2_B'] = "image-locked-dummy.png";
        // dic['C2_3_A'] = "image-locked-dummy.png";
        // dic['C2_3_B'] = "image-locked-dummy.png";
        // dic['C2_3_C'] = "image-locked-dummy.png";
        // dic['C2_4_A'] = "image-locked-dummy.png";
        // dic['C3_1_A'] = "image-locked-dummy.png";
        // dic['C3_1_B'] = "image-locked-dummy.png";;
        // dic['C3_2_A'] ="image-locked-dummy.png";
        // dic['C3_3_A'] = "image-locked-dummy.png";
        // dic['C3_4_A'] = "image-locked-dummy.png";
        // dic['C3_4_B'] = "image-locked-dummy.png";
        // //dic['C4_1_A'] = "이단자루식 간돌검";
        // dic['C4_1_A'] = "image-locked-dummy.png";
        // dic['C4_1_B'] = "image-locked-dummy.png";
        // dic['C4_2_A'] = "image-locked-dummy.png";
        // dic['C4_3_A'] = "image-locked-dummy.png";
        // return dic[item];
    }
    
    public static GetTargetName(item:string):string
    {
        var dic = {};
        dic['C2_1_A'] = "집 모양을";
        dic['C2_2_A'] = "요리사 친구의\n겹아가리 톱니무늬 바리를";
        dic['C2_2_B'] = "요리사 친구의\n시루를";
        dic['C2_3_A'] = "농사꾼 친구의 돌호미를";
        dic['C2_3_B'] = "농사꾼 친구의 돌낫을";
        dic['C2_3_C'] = "농사꾼 친구의\n반달모양 돌칼을";
        dic['C2_4_A'] = "창 모양 라벨을 찾아";
        dic['C3_1_A'] = "공방주인의 가락바퀴를 찾아";
        dic['C3_1_B'] = "공방주인의 돌끌를 찾아";
        dic['C3_2_A'] = "진주의 숫돌을 찾아";
        dic['C3_3_A'] = "옥을 찾아";
        dic['C3_4_A'] = "붉은간토기를 찾아";
        dic['C3_4_B'] = "가지무늬토기를 찾아";
        dic['C4_1_A'] = "통자루식 간돌검을 찾아";
        dic['C4_1_B'] = "간돌화살촉을 찾아";
        dic['C4_2_A'] = "토기 모양 라벨을 찾아";
        dic['C4_3_A'] = "무덤 모양 라벨을 찾아";
        
        return dic[item];
    }
    
    
    public static GetIconTarget(item:string):string
    {
    
        var dic = {};
        dic['C2_1_A'] = "C2_1_A.png";
        dic['C2_2_A'] = "C2_2_A.png";
        dic['C2_2_B'] = "C2_2_B.png";
        dic['C2_3_A'] = "C2_3_A.png";
        dic['C2_3_B'] = "C2_3_B.png";
        dic['C2_3_C'] = "C2_3_C.png";
        dic['C2_4_A'] = "C2_4_A.png";
        dic['C3_1_A'] = "C3_1_A.png";
        dic['C3_1_B'] = "C3_1_B.png";
        dic['C3_2_A'] = "C3_2_A.png";
        dic['C3_3_A'] = "C3_3_A.png";
        dic['C3_4_A'] = "C3_4_A.png";
        dic['C3_4_B'] = "C3_4_B.png";
        dic['C4_1_A'] = "C4_1_A.png";
        dic['C4_1_B'] = "C4_1_B.png";
        dic['C4_2_A'] = "C4_2_A.png";
        dic['C4_3_A'] = "C4_3_A.png";

        return dic[item];
    }
    

    public static GetIconTargetHide(item:string):string
    {
    
        var dic = {};
        dic['C2_1_A'] = "C2_1_A.png";
        dic['C2_2_A'] = "HC2_2_A.png";
        dic['C2_2_B'] = "HC2_2_B.png";
        dic['C2_3_A'] = "C2_3_A.png";
        dic['C2_3_B'] = "C2_3_B.png";
        dic['C2_3_C'] = "HC2_3_C.png";
        dic['C2_4_A'] = "C2_4_A.png";
        dic['C3_1_A'] = "HC3_1_A.png";
        dic['C3_1_B'] = "C3_1_B.png";
        dic['C3_2_A'] = "C3_2_A.png";
        dic['C3_3_A'] = "HC3_3_A.png";
        dic['C3_4_A'] = "C3_4_A.png";
        dic['C3_4_B'] = "C3_4_B.png";
        dic['C4_1_A'] = "C4_1_A.png";
        dic['C4_1_B'] = "C4_1_B.png";
        dic['C4_2_A'] = "C4_2_A.png";
        dic['C4_3_A'] = "C4_3_A.png";

        return dic[item];
    }

    public static GetTargetDesc(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "";
        dic['C2_2_A'] = "와! 금방 찾았네!\n겹아가리 톱니무늬 바리는\n 점토를 눌러 접어 입구를\n 두겹으로 만들어. 입구에\n 톱니무늬를 선을 새기듯이\n 그려넣고 톱니 가운데에\n 점으로 포인트를 줬지!\n 꼭 밥그릇처럼 생기지\n 않았어?";
        dic['C2_2_B'] = "와! 금방 찾았네!\n우리는 이 시루를 솥에\n 걸어 수증기로 떡을\n 찌듯이 밥을 지어 먹어.\n 덕분에 더 찰진 밥을\n 먹을 수 있게 되었지!";
        dic['C2_3_A'] = "찾아줬구나! 고마워!\n이걸 보면 아래쪽 끝단이\n 뾰족하고 위가 넓적하지?\n 우린 이걸 나무에 묶어\n 사용해.\n돌호미는 잡초 뽑기부터\n 씨앗 심기, 옮겨 심기,\n 흙파서 뒤집기 등을 할\n 수 있는 만능 도구야!";
        dic['C2_3_B'] = "찾아줬구나! 고마워!\n돌낫은 농작물을 몸쪽으로\n끌어당기듯이 베어서\n수확하는데에 사용하는 도구야.\n날카롭게 날을 갈아 사용해.";
        dic['C2_3_C'] = "찾아줬구나! 고마워!\n우리는 이 반달모양 돌칼로\n곡물을 수확해.\n구멍에 끈을 꿰고 이를\n 손에 잡아 사용하지.\n키가 들쑥날쑥한 작물을\n수확하는데에는 이만한 게 없어!\n덕분에 오늘 일을 할 수 있겠어!\n정말 고마워!!";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "아니 이거를 어떻게 찾았데~\n나는 한참 찾아도\n 안보였는데. 고마워.\n나는 이 가락바퀴로 실을\n 만든단다.\n신기하지?";
        dic['C3_1_B'] = "아니 이거를 어떻게 찾았데~\n나는 한참 찾아도\n안보였는데. 고마워.\n돌끌은 나무도구를 만들기\n위해서 사용하는 도구야.\n돌끌을 날카롭게 갈아서\n나무도구를 더 세밀하게\n가공하지";
        dic['C3_2_A'] = "금방 찾았구나!\n대평마을의 옥은 아주\n특별하고 귀하지! 그 옥을\n만들 때 가장 필요한게 이\n숫돌이야.옥을 이 숫돌에\n갈아서 원하는 모양을\n만들지!";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "잘 찾았구나!\n이걸 가지고 갈거야.\n 붉은간토기는 아주\n 고운점토로 만드는 귀한\n 토기야. 보통은 소형의\n 항아리 형태로 만드는데,\n 우리 대평마을은 다양한\n 모양으로 토기를 만든단다!";
        dic['C3_4_B'] = "잘 찾았구나!\n이걸 가지고 갈거야.\n 가지무늬토기는 둥근\n 몸체에 어깨부분에\n 가지무늬가 있다는 것이\n 특징이지! 고운입자의\n 바탕흙을 갈고 닦아\n 두께가 매우 얇은\n 편이란다. 어깨부분에\n 문양은 먹을 입힌거야!";
        //dic['C4_1_A'] = "이단자루식 간돌검";
        dic['C4_1_A'] = "그래 이거야!\n통자루식 간돌검은 권력을\n상징하는 물건이야.\n부러뜨려서 무덤에 같이\n넣기도 해. 의례적인\n도구의 성격을 가지고 있지.\n자, 이제 준비가 끝났으니\n어서 서두르자.";
        dic['C4_1_B'] = "그래 이거야!\n간돌화살촉은 사냥과\n전쟁에 사용된 무기야.\n사냥을 위한 화살촉과\n다르게 폭이 좁고\n날렵한게 특징이지.\n전쟁이 많아지면서 이런\n물건들이 생겨났어.";
        dic['C4_2_A'] = "붉은간토기";
        dic['C4_3_A'] = "무덤";
    
        return dic[item];
    }
    
    
    public static GetTargetDesc0(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "";
        dic['C2_2_A'] = "와! 금방 찾았네!\n겹아가리 톱니무늬 바리는\n 점토를 눌러 접어 입구를\n 두겹으로 만들어.";
        dic['C2_2_B'] = "와! 금방 찾았네!\n우리는 이 시루를 솥에\n 걸어 수증기로 떡을\n 찌듯이 밥을 지어 먹어.";
        dic['C2_3_A'] = "찾아줬구나! 고마워!\n이걸 보면 아래쪽 끝단이\n 뾰족하고 위가 넓적하지?\n 우린 이걸 나무에 묶어\n 사용해.";
        dic['C2_3_B'] = "찾아줬구나! 고마워!\n돌낫은 농작물을 몸쪽으로\n끌어당기듯이 베어서";
        dic['C2_3_C'] = "찾아줬구나! 고마워!\n우리는 이 반달모양 돌칼로\n곡물을 수확해.\n구멍에 끈을 꿰고 이를\n 손에 잡아 사용하지.";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "아니 이거를 어떻게 찾았데~\n나는 한참 찾아도\n 안보였는데.";
        dic['C3_1_B'] = "아니 이거를 어떻게 찾았데~\n나는 한참 찾아도\n안보였는데. 고마워.\n돌끌은 나무도구를 만들기\n위해서 사용하는 도구야.";
        dic['C3_2_A'] = "금방 찾았구나!\n대평마을의 옥은 아주\n특별하고 귀하지! 그 옥을";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "잘 찾았구나!\n이걸 가지고 갈거야.\n 붉은간토기는 아주\n 고운점토로 만드는 귀한\n 토기야.";
        dic['C3_4_B'] = "잘 찾았구나!\n이걸 가지고 갈거야.\n 가지무늬토기는 둥근\n 몸체에 어깨부분에\n 가지무늬가 있다는 것이\n 특징이지!";
        
        dic['C4_1_A'] = "그래 이거야!\n통자루식 간돌검은 권력을\n상징하는 물건이야.\n부러뜨려서 무덤에 같이\n넣기도 해.";
        dic['C4_1_B'] = "그래 이거야!\n간돌화살촉은 사냥과\n전쟁에 사용된 무기야.";
        dic['C4_2_A'] = "붉은간토기";
        dic['C4_3_A'] = "무덤";
    
        return dic[item];
    }
    
    public static GetTargetDesc1(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "";
        dic['C2_2_A'] = "입구에 톱니무늬를\n 선을 새기듯이\n 그려넣고 톱니 가운데에\n 점으로 포인트를 줬지!\n 꼭 밥그릇처럼 생기지\n 않았어?";
        dic['C2_2_B'] = "덕분에 더 찰진 밥을\n 먹을 수 있게 되었지!";
        dic['C2_3_A'] = "돌호미는 잡초 뽑기부터\n 씨앗 심기, 옮겨 심기,\n 흙파서 뒤집기 등을 할\n 수 있는 만능 도구야!";
        dic['C2_3_B'] = "수확하는데에 사용하는 도구야.\n날카롭게 날을 갈아 사용해.";
        dic['C2_3_C'] = "키가 들쑥날쑥한 작물을\n수확하는데에는 이만한 게 없어!\n덕분에 오늘 일을 할 수 있겠어!\n정말 고마워!!";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "나는 이 가락바퀴로 실을\n 만든단다.\n신기하지?";
        dic['C3_1_B'] = "돌끌을 날카롭게 갈아서\n나무도구를 더 세밀하게\n가공하지";
        dic['C3_2_A'] = "만들 때 가장 필요한게 이\n숫돌이야.옥을 이 숫돌에\n갈아서 원하는 모양을\n만들지!";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "보통은 소형의\n 항아리 형태로 만드는데,\n 우리 대평마을은 다양한\n 모양으로 토기를 만든단다!";
        dic['C3_4_B'] = "고운입자의\n 바탕흙을 갈고 닦아\n 두께가 매우 얇은\n 편이란다. 어깨부분에\n 문양은 먹을 입힌거야!";
        
        dic['C4_1_A'] = "의례적인 도구의 성격을\n 가지고 있지.\n자, 이제 준비가 끝났으니\n어서 서두르자.";
        dic['C4_1_B'] = "사냥을 위한 화살촉과 다르게 폭이 좁고\n날렵한게 특징이지.\n전쟁이 많아지면서 이런\n물건들이 생겨났어.";
        dic['C4_2_A'] = "붉은간토기";
        dic['C4_3_A'] = "무덤";
    
        return dic[item];
    }
    

    public static GetTargetDesc2(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "";
        dic['C2_2_A'] = "";
        dic['C2_2_B'] = "";
        dic['C2_3_A'] = "농사꾼 친구의 돌호미를\n찾아주었구나!!\n농사꾼 친구가 기뻐하니\n기분이 참 좋다. 그렇지?";
        dic['C2_3_B'] = "농사꾼 친구의 돌낫을\n찾아주었구나!!\n농사꾼 친구가 기뻐하니\n기분이 참 좋다. 그렇지?";
        dic['C2_3_C'] = "농사꾼 친구의 반달모양\n돌칼을 찾아주었구나!!\n농사꾼 친구가 기뻐하니\n기분이 참 좋다. 그렇지?";
        dic['C2_4_A'] = "사냥법";
        dic['C3_1_A'] = "가락바퀴";
        dic['C3_1_B'] = "돌끌";
        dic['C3_2_A'] = "홈줄이 있는 숫돌";
        dic['C3_3_A'] = "옥";
        dic['C3_4_A'] = "붉은간토기";
        dic['C3_4_B'] = "가지무늬토기";
        //dic['C4_1_A'] = "이단자루식 간돌검";
        dic['C4_1_A'] = "통자루식 간돌검";
        dic['C4_1_B'] = "간돌화살촉";
        dic['C4_2_A'] = "붉은간토기";
        dic['C4_3_A'] = "무덤";
    
        return dic[item];
    }
    
    public static GetClearDesc(zone:string):string
    {
        if (zone=="")
            return "";

        var dic = {};
    
        dic['C2'] = "풍요, 농경생활에서\n재미있는 시간 보냈니?\n이제 다음으로 넘어가볼까?";
        dic['C3'] = "기술, 대평공방에서\n재미있는 시간 보냈니?\n이제 다음으로 넘어가볼까?";
        dic['C4'] = "영원, 무덤과 의례에서\n의미있는 시간 보냈니?";
        return dic[zone];
    }
    
    public static GetClearButton(zone:string):string
    {
        if (zone=="")
            return "";

        var dic = {};
    
        dic['C2'] = "기술, 대평공방으로!";
        dic['C3'] = "영원,무덤과 의례로!";
        dic['C4'] = "다음";
    
        return dic[zone];
    }
    
    // public static GetBG(item:string):string
    // {
    //     if (item=="")
    //         return "";
    
    //     var dic = {};
    
    //     dic['C2_1_A'] = "zone2bg.jpg";
    //     dic['C2_2_A'] =  "zone2bg.jpg";
    //     dic['C2_2_B'] =  "zone2bg.jpg";
    //     dic['C2_3_A'] =  "zone2bg.jpg";
    //     dic['C2_3_B'] =  "zone2bg.jpg";
    //     dic['C2_3_C'] =  "zone2bg.jpg";
    //     dic['C2_4_A'] =  "zone2bg.jpg";
    //     dic['C3_1_A'] =  "zone2bg.jpg";
    //     dic['C3_1_B'] =  "zone2bg.jpg";
    //     dic['C3_2_A'] =  "zone3bg.jpg";
    //     dic['C3_3_A'] = "zone3bg.jpg";
    //     dic['C3_4_A'] =  "zone3bg.jpg";
    //     dic['C3_4_B'] = "zone3bg.jpg";
    //     dic['C4_1_A'] =  "zone3bg.jpg";
    //     dic['C4_1_B'] = "zone3bg.jpg";
    //     dic['C4_2_A'] = "zone4bg.jpg";
    //     dic['C4_3_A'] = "zone4bg.jpg";
    
    //     return dic[item];
    // }
    // public static GetBGTitle(item:string):string
    // {
    //     if (item=="")
    //         return "";
    
    //     var dic = {};
    
    //     dic['C2_1_A'] = "zone2title.png";
    //     dic['C2_2_A'] =  "zone2title.png";
    //     dic['C2_2_B'] =  "zone2title.png";
    //     dic['C2_3_A'] =  "zone2title.png";
    //     dic['C2_3_B'] =  "zone2title.png";
    //     dic['C2_3_C'] =  "zone2title.png";
    //     dic['C2_4_A'] =  "zone2title.png";
    //     dic['C3_1_A'] =  "zone2title.png";
    //     dic['C3_1_B'] =  "zone2title.png";
    //     dic['C3_2_A'] =  "zone3title.png";
    //     dic['C3_3_A'] = "zone3title.png";
    //     dic['C3_4_A'] =  "zone3title.png";
    //     dic['C3_4_B'] = "zone3title.png";
    //     dic['C4_1_A'] =  "zone3title.png";
    //     dic['C4_1_B'] = "zone3title.png";
    //     dic['C4_2_A'] = "zone4title.png";
    //     dic['C4_3_A'] = "zone4title.png";
    
    //     return dic[item];
    // }
    

    public static GetBG(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "zone2bg.jpg";
        dic['C2_2_A'] =  "zone2bg.jpg";
        dic['C2_2_B'] =  "zone2bg.jpg";
        dic['C2_3_A'] =  "zone2bg.jpg";
        dic['C2_3_B'] =  "zone2bg.jpg";
        dic['C2_3_C'] =  "zone2bg.jpg";
        dic['C2_4_A'] =  "zone2bg.jpg";
        dic['C3_1_A'] =  "zone3bg.jpg";
        dic['C3_1_B'] =  "zone3bg.jpg";
        dic['C3_2_A'] =  "zone3bg.jpg";
        dic['C3_3_A'] = "zone3bg.jpg";
        dic['C3_4_A'] =  "zone3bg.jpg";
        dic['C3_4_B'] = "zone3bg.jpg";
        dic['C4_1_A'] =  "zone4bg.jpg";
        dic['C4_1_B'] = "zone4bg.jpg";
        dic['C4_2_A'] = "zone4bg.jpg";
        dic['C4_3_A'] = "zone4bg.jpg";
    
        return dic[item];
    }
    public static GetBGTitle(item:string):string
    {
        if (item=="")
            return "";
    
        var dic = {};
    
        dic['C2_1_A'] = "zone2title.png";
        dic['C2_2_A'] =  "zone2title.png";
        dic['C2_2_B'] =  "zone2title.png";
        dic['C2_3_A'] =  "zone2title.png";
        dic['C2_3_B'] =  "zone2title.png";
        dic['C2_3_C'] =  "zone2title.png";
        dic['C2_4_A'] =  "zone2title.png";
        dic['C3_1_A'] =  "zone3title.png";
        dic['C3_1_B'] =  "zone3title.png";
        dic['C3_2_A'] =  "zone3title.png";
        dic['C3_3_A'] = "zone3title.png";
        dic['C3_4_A'] =  "zone3title.png";
        dic['C3_4_B'] = "zone3title.png";
        dic['C4_1_A'] =  "zone4title.png";
        dic['C4_1_B'] = "zone4title.png";
        dic['C4_2_A'] = "zone4title.png";
        dic['C4_3_A'] = "zone4title.png";
    
        return dic[item];
    }


}
 
 export {Config}
 