import * as PIXI from "pixi.js";

import {DataManager} from "./DataManager.ts";
import {Scene} from "./Scene.ts";
import { Button } from "./Button.ts";
import { Image } from "./Image.ts";
import {Config} from "./Config.ts";
import {TextBox} from "./TextBox.ts";
import {EffectAnimation} from "./EffectAnimation.ts";
import { SceneManager, SCENE } from "./SceneManager.ts";
import {ModelManager} from "./ModelManager.ts";
import {TextInput} from "pixi-textinput-v5"
import { Ease, ease } from "pixi-ease"
import { sound } from '@pixi/sound';

class SceneLogin extends Scene
{
    index:number = 0; // 전화번호
    index2:number = 0; // 패스워드
    TextBox1:TextBox;

    view0:PIXI.Container;
    view1:PIXI.Container;
    view2:PIXI.Container; // 암호
    view3:PIXI.Container; // 패스워드


    constructor() 
    {
       super();

       this.loader.add("assets/hobot1.json")
       .load(
       ()=>{ 
           this.loading=false;
           this.start();
       }
       );

   }

   start():void
  {

    sound.stopAll();

    sound.play('bgm1',{ loop:true, volume:0.3 });


       this.view0 = new PIXI.Container();

       super.addChild( this.view0);
       let  bg = new Image("startbg.jpg", true);
       bg.x = Config.SCREEN_WIDTH/2;
       bg.y = Config.SCREEN_HEIGHT/2; 
       this.view0.addChild(bg);


       let startButton = new Button("시작하기",30 );
       startButton.x = Config.SCREEN_WIDTH / 2;
       startButton.y = 882+93;
       this.view0.addChild(startButton);
     
       startButton.on('pointerup',()=>
       {
          if(this.view0!=null)
          {
            super.removeChild(this.view0);
          }

          this.OnClickStart(); // 
 
       } );   
    }


    public  OnClickStart():void
    {
      
        // this.view1 = new PIXI.Container();
        // this.view2 = new PIXI.Container();
        // this.view3 = new PIXI.Container();
        // this.view2.visible = false;
        // this.view3.visible = false;

        // super.addChild(this.view1);
        // super.addChild(this.view2);
        // super.addChild(this.view3);

        let  bg = new Image("bg-galaxy2.jpg", true);
        bg.x = Config.SCREEN_WIDTH/2;
        bg.y = Config.SCREEN_HEIGHT/2; 
        super.addChild(bg);
     
        const urlParameter = window.location.search;
        const urlParams = new URLSearchParams(urlParameter);
        let phoneNo = urlParams.get("phoneNo");
        
        let IsDebug = urlParams.get("debug");
        if ( IsDebug=="true")
        {
           ModelManager.instance().setData("ardebug","true");
        }else{
           ModelManager.instance().setData("ardebug","false");
        }
        
        let IsSkip = urlParams.get("skip");
        if ( IsSkip=="true")
        {
           ModelManager.instance().setData("arskip","true");
        }else{
           ModelManager.instance().setData("arskip","false");
        }
        
        console.log(phoneNo);

        const userAgent = navigator.userAgent.toLowerCase();

        var isMobile = /iPhone|Android/i.test(navigator.userAgent);
        console.log(isMobile);

        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        
        console.log(isTablet)

        if(isMobile) {
            console.log("Mobile")
        }else if(isTablet){
            console.log("Tablet")
        }
        
        if ( (phoneNo=="" || phoneNo==undefined || phoneNo==null || phoneNo=="null")  ) // admin
        {

          console.log("phoneNo==null");

          ModelManager.instance().init();

          //var THIS = this;

          let adminButton = new Button("____",30 );
          adminButton.x = Config.SCREEN_WIDTH - 173/2;
          adminButton.y = 109/2;
          adminButton.alpha = 0;
          super.addChild(adminButton);
          
          adminButton.on('pointerup', ()=>
          {

            this.index++;
          
            if (this.index>=5)
            {
              super.removeChild(adminButton);

              if(this.view2!=null)
              {
                super.removeChild(this.view2);
              }
      
              this.view2 = new PIXI.Container();
      
              super.addChild(this.view2);


              let text= new PIXI.Text("전화번호를 입력해주세요", {
              fontFamily: "font1000",
              fontSize: 40,
              fill: "#ffffff",
              stroke: "black",
              strokeThickness: 0,
              letterSpacing : 0,
              align : 'center'
              });
        
              text.anchor.set(0.5);
              text.x= Config.SCREEN_WIDTH/2;
              text.y= 312;
              this.view2.addChild(text);

              var input = new TextInput({
                  input: {fontSize: '40px',
                  width: '500px',
                }, 
                  box: {fill: 0xEEEEEE}
              })

              input.x = 70;
              input.y = 413;
              //input.placeholder = 'Enter your Text...'
              this.view2.addChild(input);
              input.focus();
              

              let startButton = new Button("시작하기",40);

              startButton.x = Config.SCREEN_WIDTH/2;
              startButton.y = 413+99+100+305;
              
              this.view2.addChild(startButton);

              startButton.on('pointerup',()=>
              {
                if ( ModelManager.instance().SHA256(ModelManager.instance().getData("pw"))!="a5c3dd48facf21ed5f916d0ae979091fead570e6aea6c1d8038d1f68b26fa51f" )
                {
                    console.log("error");
                    return ;
                }

                phoneNo  = input.text;
                input.text ="";
                
                console.log("phoneNo:"+phoneNo);

                 ModelManager.instance().postData(Config.URL_LOGIN,{phoneNo:phoneNo.toString() }).then((res)=>{

                    if(this.view2!=null)
                    {
                      this.view2.removeChild(input);
                      super.removeChild(this.view2);
                    }
                    
                    //this.PlayStart();
                    if ( res.code == 200 )
                    {
                      // admin 전화 번호 입력후 성공후 진행
                      console.log("phoneNo!=null 2")
                      ModelManager.instance().setData("userID",res.data.userId);
                      ModelManager.instance().setData("token",res.data.token);
                      ModelManager.instance().setData("phoneNo",phoneNo);
                      ModelManager.instance().setData("ordering",res.data.ordering.toString() );
                      ModelManager.instance().setItemList();
                    }

                    SceneManager.instance().SetScene(SCENE.ARCHECKSUCCESS);
                    console.log( JSON.stringify(res) );
                  });

              } );   
          }
        });

            // 패스웥드
          let adminButton2 = new Button("____",30 );
          adminButton2.x = 173/2;
          adminButton2.y = 109/2;
          adminButton2.alpha = 0;
          super.addChild(adminButton2);

          adminButton2.on('pointerup', ()=>
          {

            this.index2++;
            
            if (this.index2>=5)
            {
                  super.removeChild(adminButton2);
                  
                  if(this.view3!=null)
                  {
                    super.removeChild(this.view3);
                  }
          
                  this.view3 = new PIXI.Container();
          
                  super.addChild(this.view3);

                  let text= new PIXI.Text("패스워드를 입력해주세요", {
                  fontFamily: "font1000",
                  fontSize: 40,
                  fill: "#ffffff",
                  stroke: "black",
                  strokeThickness: 0,
                  letterSpacing : 0,
                  align : 'center'
                  });
            
                  text.anchor.set(0.5);
                  text.x= Config.SCREEN_WIDTH/2;
                  text.y= 312;
                  this.view3.addChild(text);

                  var input = new TextInput({
                      input: {fontSize: '40px',
                      width: '500px',
                    }, 
                      box: {fill: 0xEEEEEE}
                  })

                  input.x = 70;
                  input.y = 413;
                  //input.placeholder = 'Enter your Text...'
                  this.view3.addChild(input);
                  input.focus();

                  let startButton = new Button("시작하기",40);

                  startButton.x = Config.SCREEN_WIDTH/2;
                  startButton.y = 413+99+100+305;
                  
                  this.view3.addChild(startButton);

                  startButton.on('pointerup',()=>
                  {
                    ModelManager.instance().setData("pw", input.text );
                    input.text ="";
                    if(this.view3!=null)
                    {
                      this.view3.removeChild(input);
                      super.removeChild(this.view3);
                    }
                  
                  } );   
            }
          });

        }else{

          // 번호가 있는 경우

          console.log("phoneNo!=null")
          // 번호 저장
           //ModelManager.instance().setData("phoneNo",phoneNo);

           // 인증요청
           ModelManager.instance().postData(Config.URL_LOGIN,{phoneNo:phoneNo}).then((res)=>{
              if(this.view2!=null)
              {
                super.removeChild(this.view2);
              }
              
              //SceneManager.instance().SetScene(SCENE.ARCHECKSUCCESS);
              this.PlayStart();

              if ( res.code == 200 )
              {
                 console.log("phoneNo!=null 2")
                 ModelManager.instance().setData("userID",res.data.userId);
                 ModelManager.instance().setData("token",res.data.token);
                 ModelManager.instance().setData("phoneNo",phoneNo);
                 ModelManager.instance().setData("ordering",res.data.ordering.toString() );
                 ModelManager.instance().setItemList();
              }

              console.log( JSON.stringify(res) );
              
            });
        }
    }

    public  PlayStart():void
    {
        if(this.view1!=null)
        {
          super.removeChild(this.view1);
        }

        this.view1 = new PIXI.Container();
        super.addChild(this.view1);

       

        //let cha = new Image("cha.png");
        let cha = new EffectAnimation(this.GetTexture("assets/hobot1.json"),1 );


        this.view1.addChild(cha);
        cha.x = 100;
        cha.y = 100; 

        cha.curve([130,130,600,250,-600,500,500,700]);
        
        cha.scale.x = 0.5;
        cha.scale.y = 0.5;

        const eff1 = ease.add(cha, { scale: 1  } , { reverse: false, duration: 3000, ease: 'easeInSine'});
        
        eff1.once('complete', () => {
          this.TextBox1= new TextBox("", 30);
          this.TextBox1.x =Config.SCREEN_WIDTH/2;
          this.TextBox1.y = 100 + 234/2;
          this.view1.addChild(this.TextBox1);
          //this.TextBox1.init("안녕!\n너무 너무 만나서 반가워",30);
          this.TextBox1.init("안녕!\n만나서 반가워",30);
          }  
        );

        //setTimeout(()=>{this.TextBox1.init("우리 같이 청동기 시대\n여행을 떠나 관람할거야.",30);},7000);

        setTimeout(()=>{this.TextBox1.init("우리 같이 청동기 시대\n여행을 떠나보자.",30);},7000);

        //setTimeout(()=>{this.TextBox1.init("우선 관람에 꼭 필요한\nAR 테스트를 진행해보자",30);},11000);
        setTimeout(()=>{this.TextBox1.init("여행을 떠나기 전 꼭 필요한\nAR 테스트를 진행해보자",30);},11000);
    
        let startButton = new Button("테스트하기",30 );
         
        startButton.x = Config.SCREEN_WIDTH / 2;
        startButton.y = 882+43;
        
        startButton.DelayActive(14000);
        this.view1.addChild(startButton);

        startButton.on('pointerup',()=>
        {
            console.log("test:"+ Config.TEST);

            if ( Config.TEST == true )
            {
              SceneManager.instance().goURL("/ARCheckSuccess");
            }else
            {
              SceneManager.instance().goURL("/test-ar");
            }
            
        } );   
    }

    

} 

export {SceneLogin}
